import React from "react";
import { Typography } from "antd";

type TitleProps = React.HTMLAttributes<HTMLElement> & {
  level: 1 | 2 | 3 | 4 | 5;
  children?: React.ReactNode;
};

function Title({ level, className, children }: TitleProps) {
  return (
    <Typography.Title className={className} level={level}>
      {children}
    </Typography.Title>
  );
}

Title.defaultProps = {
  level: 2,
};

export default Title;
