import React from 'react'
import { IoMdArrowRoundBack } from "react-icons/io"

function Back({ onClick }: { onClick: () => void }) {
  return (
    <div className="inline-block p-1 rounded-full bg-[#1d1d1d] hover:cursor-pointer" onClick={onClick}>
      <IoMdArrowRoundBack color="white"/>
    </div>
  )
}

export default Back