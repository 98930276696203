import { Space, Spin } from "antd";
import PageContainer from "pages/container/PageContainer";
import React, { memo, useCallback, useEffect } from "react";
import { getSmsActivities } from "redux/reducers/sms";
import { SmsSelector } from "redux/selectors/sms";
import { BsCreditCardFill } from "react-icons/bs";
import UploadSmsExcel from "./UploadSmsExcel";
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";
import SmsTable from "./SmsTable";

function SmsPage() {
  const dispatch = useDispatch();
  const loading = useAppSelector(SmsSelector.loading);
  const credit = useAppSelector(SmsSelector.credit);
  const smsActivities = useAppSelector(SmsSelector.smsActivities);

  const fetchSmsActivities = useCallback(() => {
    dispatch(getSmsActivities());
  }, [dispatch])

  useEffect(() => {
    fetchSmsActivities();
  }, [fetchSmsActivities]);

  return (
    <PageContainer className="sms_page">
      <Spin spinning={loading}>
        <div>
          <div className="flex justify-between p-4">
            <div className="credit text-lg">
              <Space>
                <BsCreditCardFill />
                <span>เครดิต: {credit}</span>
              </Space>
            </div>
            <div className="upload-sms-excel">
              <UploadSmsExcel refresh={fetchSmsActivities}/>
            </div>
          </div>
        </div>
        <div className="activities_table">
          <SmsTable dataSource={smsActivities} />
        </div>
      </Spin>
    </PageContainer>
  );
}

export default memo(SmsPage);
