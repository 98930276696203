import create from "zustand";
import { AccountId, IAccount, KbankRef, ITransaction, ITransactionKbankDetail, TransferHistory } from "types/account";
import { PageLevel } from "./AccountManagementPage";
import { Pagination } from "types";
import { defaultPagination } from "const";
import cloneDeep from "lodash/cloneDeep";
import { TablePaginationConfig } from "antd/es/table";
import update from "immutability-helper";

const _defaultPagination: Pagination = {
  ...defaultPagination,
  position: ["bottomRight"],
};

type State = {
  loading: boolean;
  fetchingKbankDetail: KbankRef;
  accounts: IAccount[];
  error?: string;
  forceUpdate: number;
  pageLevel: PageLevel;
  viewAccountId?: string;
  balance: number;
  transactions: ITransaction[];
  paginationTransactions: Pagination;
  openWithdrawModal: boolean;
  openWithdrawHistoriesModal: boolean;
  paginationWithdrawHistories: Pagination;
  fetchingWithdrawHistories: boolean;
  withdrawHistories: TransferHistory[];
};

type Actions = {
  setLoading: (loading: boolean) => void;
  setAccounts: (accounts: IAccount[]) => void;
  setError: (error: string) => void;
  setForceUpdate: () => void;
  setPageLevel: (pageLevel: PageLevel) => void;
  nextPageLevel: () => void;
  prevPageLevel: () => void;
  viewAccount: (accountId: AccountId) => void;
  setBalance: (balance: number) => void;
  setTransactions: (transactions: ITransaction[]) => void;
  setPaginationTransactions: (pagination: Partial<TablePaginationConfig>) => void;
  setKbankTransDetail: (detail: ITransactionKbankDetail) => void;
  setFetchingKbankDetail: (fetchingKbankDetail: KbankRef) => void;
  setOpenWithdrawModal: (openWithdrawModal: boolean) => void;
  setOpenWithdrawHistoriesModal: (openWithdrawHistoriesModal: boolean) => void;
  setPaginationWithdrawHistories: (pagination: Partial<TablePaginationConfig>) => void;
  setWithdrawHistories: (withdrawHistories: TransferHistory[]) => void;
  setFetchingWithdrawHistories: (fetchingWithdrawHistories: boolean) => void;
  resetPaginationWithdrawHistories: () => void;
  resetState: () => void;
};

const initialState: State = {
  loading: false,
  fetchingKbankDetail: undefined,
  accounts: [],
  forceUpdate: 0,
  pageLevel: 0,
  viewAccountId: undefined,
  balance: 0,
  transactions: [],
  paginationTransactions: cloneDeep(_defaultPagination),
  openWithdrawModal: false,
  openWithdrawHistoriesModal: false,
  paginationWithdrawHistories: cloneDeep(_defaultPagination),
  withdrawHistories: [],
  fetchingWithdrawHistories: false,
};

type StoreState = State & Actions;

const useStore = create<StoreState>((set) => ({
  ...cloneDeep(initialState),
  setLoading: (loading: boolean) => set({ loading }),
  setAccounts: (accounts: IAccount[]) => set({ accounts }),
  setError: (error: string) => set({ error }),
  setForceUpdate: () => set((state) => ({ forceUpdate: state.forceUpdate + 1 })),
  setPageLevel: (pageLevel: PageLevel) => set({ pageLevel }),
  nextPageLevel: () => set((state) => ({ pageLevel: (state.pageLevel + 1) as PageLevel })),
  prevPageLevel: () => set((state) => ({ pageLevel: Math.max(state.pageLevel - 1, 0) as PageLevel })),
  viewAccount: (accountId: AccountId) =>
    set((state) => {
      return {
        viewAccountId: accountId,
        balance: 0,
        transactions: [],
        paginationTransactions: {
          ...state.paginationTransactions,
          current: 1,
        },
      };
    }),
  setBalance: (balance: number) => set({ balance }),
  setTransactions: (transactions: ITransaction[]) => set({ transactions }),
  setPaginationTransactions: (pagination: Partial<TablePaginationConfig>) =>
    set((state) => ({
      paginationTransactions: update(state.paginationTransactions, { $merge: pagination }),
    })),
  setKbankTransDetail: (detail: ITransactionKbankDetail) =>
    set((state) => {
      const index = state.transactions.findIndex((t) => /[^,]+/.exec(t.kbankRef ?? "")?.[0] === detail.rqUid);
      return update(state, { transactions: { [index]: { $merge: { kbankTransDetail: detail } } } });
    }),
  setFetchingKbankDetail: (fetchingKbankDetail: KbankRef) => set({ fetchingKbankDetail }),
  setOpenWithdrawModal: (openWithdrawModal: boolean) => set({ openWithdrawModal }),
  setOpenWithdrawHistoriesModal: (openWithdrawHistoriesModal: boolean) => set({ openWithdrawHistoriesModal }),
  setPaginationWithdrawHistories: (pagination: Partial<TablePaginationConfig>) =>
    set((state) => ({
      paginationWithdrawHistories: update(state.paginationWithdrawHistories, { $merge: pagination }),
    })),
  resetPaginationWithdrawHistories: () => set({ paginationWithdrawHistories: cloneDeep(_defaultPagination) }),
  setWithdrawHistories: (withdrawHistories: TransferHistory[]) => set({ withdrawHistories }),
  setFetchingWithdrawHistories: (fetchingWithdrawHistories: boolean) => set({ fetchingWithdrawHistories }),
  resetState: () => set(cloneDeep(initialState)),
}));

export const useLoading = () => useStore((state: StoreState) => state.loading);
export const useFetchingKbankDetail = () => useStore((state: StoreState) => state.fetchingKbankDetail);
export const useAccounts = () => useStore((state: StoreState) => state.accounts);
export const useError = () => useStore((state: StoreState) => state.error);
export const useForceUpdate = () => useStore((state: StoreState) => state.forceUpdate);
export const usePageLevel = () => useStore((state: StoreState) => state.pageLevel);
export const useViewAccountId = () => useStore((state: StoreState) => state.viewAccountId);
export const useBalance = () => useStore((state: StoreState) => state.balance);
export const useTransactions = () => useStore((state: StoreState) => state.transactions);
export const usePaginationTransactions = () => useStore((state: StoreState) => state.paginationTransactions);
export const useOpenWithdrawModal = () => useStore((state: StoreState) => state.openWithdrawModal);
export const useOpenWithdrawHistoriesModal = () => useStore((state: StoreState) => state.openWithdrawHistoriesModal);
export const usePaginationWithdrawHistories = () => useStore((state: StoreState) => state.paginationWithdrawHistories);
export const useFetchingWithdrawHistories = () => useStore((state: StoreState) => state.fetchingWithdrawHistories);
export const useWithdrawHistories = () => useStore((state: StoreState) => state.withdrawHistories);

export const useSetLoading = () => useStore((state: StoreState) => state.setLoading);
export const useSetAccounts = () => useStore((state: StoreState) => state.setAccounts);
export const useSetError = () => useStore((state: StoreState) => state.setError);
export const useSetForceUpdate = () => useStore((state: StoreState) => state.setForceUpdate);
export const useSetPageLevel = () => useStore((state: StoreState) => state.setPageLevel);
export const useNextPageLevel = () => useStore((state: StoreState) => state.nextPageLevel);
export const usePrevPageLevel = () => useStore((state: StoreState) => state.prevPageLevel);
export const useViewAccount = () => useStore((state: StoreState) => state.viewAccount);
export const useSetBalance = () => useStore((state: StoreState) => state.setBalance);
export const useSetTransactions = () => useStore((state: StoreState) => state.setTransactions);
export const useSetPaginationTransactions = () => useStore((state: StoreState) => state.setPaginationTransactions);
export const useSetKbankTransDetail = () => useStore((state: StoreState) => state.setKbankTransDetail);
export const useSetFetchingKbankDetail = () => useStore((state: StoreState) => state.setFetchingKbankDetail);
export const useSetOpenWithdrawModal = () => useStore((state: StoreState) => state.setOpenWithdrawModal);
export const useSetOpenWithdrawHistoriesModal = () =>
  useStore((state: StoreState) => state.setOpenWithdrawHistoriesModal);
export const useSetPaginationWithdrawHistories = () =>
  useStore((state: StoreState) => state.setPaginationWithdrawHistories);
export const useResetPaginationWithdrawHistories = () =>
  useStore((state: StoreState) => state.resetPaginationWithdrawHistories);
export const useSetFetchingWithdrawHistories = () =>
  useStore((state: StoreState) => state.setFetchingWithdrawHistories);
export const useSetWithdrawHistories = () => useStore((state: StoreState) => state.setWithdrawHistories);
export const useResetState = () => useStore((state: StoreState) => state.resetState);
