import { message } from "antd";
import React from "react";
import { escapeRegExp, isEmpty } from "./common-util";

export function displayTextFilter(text: string, searchText: string, props = {}): JSX.Element {
  if (searchText && typeof searchText === "string" && !isEmpty(text)) {
    const searchTexts = searchText
      .toLowerCase()
      .split(" ")
      .map((t) => t.replace(/_+/g, " ")); // replace '_' in meaning space bar
    const searchEscapedTexts = searchTexts.map((t) => escapeRegExp(t));
    const regTexts = searchEscapedTexts.map((t) => `(${t})`).join("|");
    return (
      <span {...props}>
        {String(text)
          .split(new RegExp(regTexts, "i"))
          .filter((v) => !!v)
          .map((fragment, i) =>
            searchTexts.includes(fragment.toLowerCase()) ? (
              <strong key={i} className="text-emerald-400">
                {fragment}
              </strong>
            ) : (
              fragment
            )
          )}
      </span>
    );
  } else {
    return <span {...props}>{text}</span>;
  }
}

export const jsonPretty = (text = "") => {
  try {
    const jsonObj = JSON.parse(text.trim());
    text = JSON.stringify(jsonObj, null, "\t");
  } catch (error) {}
  return text;
};

type CopyConfig = {
  autoFormatJson?: boolean;
};
export const copyTextToClipBoard = (text = "", config: CopyConfig = {}) => {
  text = text.trim();
  if (config.autoFormatJson) {
    text = jsonPretty(text);
  }

  const input = document.createElement("textarea");
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand("copy");
  document.body.removeChild(input);

  message.success("คัดลอกเรียบร้อย");
  return result;
};
