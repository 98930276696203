import { Modal, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import dayjs from "dayjs";
import { useBankList } from "pages/global-store";
import React, { memo, useCallback, useEffect } from "react";
import { TransferHistory } from "types/account";
import { formatAccountNo } from "utils/format-util";
import { formatNumber } from "utils/format-util";
import { rowIndex } from "utils/pagination-util";
import find from "lodash/find";
import {
  useFetchingWithdrawHistories,
  useOpenWithdrawHistoriesModal,
  usePaginationWithdrawHistories,
  useResetPaginationWithdrawHistories,
  useSetFetchingWithdrawHistories,
  useSetOpenWithdrawHistoriesModal,
  useSetPaginationWithdrawHistories,
  useSetWithdrawHistories,
  useWithdrawHistories,
} from "./store";
import { AccountManagementService } from "services/account-management-service";
import { alertError } from "utils/error-util";

type WithdrawHistoriesModalProps = {
  accountId: string | undefined;
};

function WithdrawHistoriesModal({ accountId }: WithdrawHistoriesModalProps) {
  const visible = useOpenWithdrawHistoriesModal();
  const onVisibleChange = useSetOpenWithdrawHistoriesModal();
  const fetchingWithdrawHistories = useFetchingWithdrawHistories();
  const setFetchingWithdrawHistories = useSetFetchingWithdrawHistories();
  const withdrawHistories = useWithdrawHistories();
  const setWithdrawHistories = useSetWithdrawHistories();
  const pagination = usePaginationWithdrawHistories();
  const setPagination = useSetPaginationWithdrawHistories();
  const resetPagination = useResetPaginationWithdrawHistories();
  const bankList = useBankList();

  useEffect(() => {
    if (!visible) {
      resetPagination();
    }
  }, [visible]);

  useEffect(() => {
    if (visible && accountId) {
      const controller = new AbortController();
      (async () => {
        try {
          setFetchingWithdrawHistories(true);
          const response = await AccountManagementService.fetchTransferHistories(
            {
              accountId: accountId as string,
              page: pagination.current ?? 1,
              perPage: pagination.pageSize ?? 10,
            },
            controller.signal
          );
          setWithdrawHistories(response.data.result.histories);
          setPagination({ total: response.data.result.total });
        } catch (error) {
          alertError(error);
          console.log(error.message);
        } finally {
          if (!controller.signal.aborted) {
            setFetchingWithdrawHistories(false);
          }
        }
      })();
      return () => {
        controller.abort();
      };
    }

    return;
  }, [accountId, pagination.current, pagination.pageSize, visible]);

  const getBankName = (bankCode: string) => {
    return find(bankList, { bankCode })?.bankThName;
  };

  const onTableChange = useCallback((pagination: TablePaginationConfig) => {
    setPagination(pagination);
  }, []);

  const _pagination = pagination as TablePaginationConfig;
  const columns: ColumnsType<TransferHistory> = [
    {
      title: "#",
      key: "__no__",
      width: 50,
      align: "center",
      render: (_, __, index: number) => rowIndex(_pagination.current, _pagination.pageSize, index),
    },
    {
      title: "ธนาคาร",
      dataIndex: "toBankCode",
      render: getBankName,
    },
    {
      title: "เลขบัญชี",
      dataIndex: "toAccountNo",
      align: "center",
      render: formatAccountNo,
    },
    {
      title: "จำนวน",
      dataIndex: "amount",
      align: "right",
      render: (amount: number) => formatNumber(amount, 2),
    },
    {
      title: "วันที่/เวลา",
      dataIndex: "createDate",
      render: (createDate: Date) => dayjs(createDate).format("DD/MM/YYYY HH:mm:ss"),
    },
  ];

  return (
    <Modal
      title="ประวัติการโอนเงินออก"
      open={visible}
      onCancel={() => onVisibleChange(false)}
      footer={null}
      width={800}
      destroyOnClose
    >
      <div>
        <Table
          className="c-table header-center"
          loading={fetchingWithdrawHistories}
          rowKey="id"
          columns={columns}
          dataSource={withdrawHistories}
          pagination={pagination}
          onChange={onTableChange}
          scroll={{
            x: 1246,
          }}
          sticky
        />
      </div>
    </Modal>
  );
}

export default memo(WithdrawHistoriesModal);
