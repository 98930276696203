import { message } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import LabelUnit from "components/Text/LabelUnit";
import PageContainer from "pages/container/PageContainer";
import React, { memo, useCallback, useEffect } from "react";
import { WithdrawReportService } from "services/withdraw-report-service";
import { WithdrawReport } from "types/withdraw-report";
import { alertError } from "utils/error-util";
import WithdrawReportTable from "./WithdrawReportTable";
import FilterSection from "./FilterSection";
import {
  useFilters,
  useForceUpdate,
  useLoading,
  usePagination,
  useSetForceUpdate,
  useSetLoading,
  useSetPagination,
  useSetStatements,
  useSetTotal,
  useStatements,
  useTotal,
} from "./store";

function WithdrawReportPage() {
  const loading = useLoading();
  const statements = useStatements();
  const pagination = usePagination();
  const total = useTotal();
  const forceUpdate = useForceUpdate();
  const setLoading = useSetLoading();
  const setTotal = useSetTotal();
  const setPagination = useSetPagination();
  const setStatements = useSetStatements();
  const filters = useFilters();
  const setForceUpdate = useSetForceUpdate();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await WithdrawReportService.fetchWithdrawReport({
          playerName: filters.playerName ? filters.playerName : undefined,
          status: filters.status,
          page: pagination.current ?? 0,
          perPage: pagination.pageSize ?? 0,
        });
        const { currentPage, perPage, total, withdrawList } = response.data.result;
        setStatements(withdrawList);
        setPagination({ current: currentPage, pageSize: perPage });
        setTotal(total);
      } catch (error) {
        alertError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [forceUpdate, filters, pagination]);

  const onSearch = useCallback(() => {
    setPagination({ current: 1 });
    setForceUpdate();
  }, []);

  const refresh = useCallback(() => {
    setForceUpdate();
  }, []);

  const onTableChange = useCallback((pagination: TablePaginationConfig) => {
    setPagination(pagination);
  }, []);

  const onApprove = useCallback(
    async (data: WithdrawReport) => {
      try {
        setLoading(true);
        await WithdrawReportService.approve(data.id);
        message.success("ยืนยันรายการสำเร็จ");
        refresh();
      } catch (error) {
        alertError(error);
      } finally {
        setLoading(false);
      }
    },
    [refresh]
  );

  const onReject = useCallback(
    async (data: WithdrawReport) => {
      try {
        setLoading(true);
        await WithdrawReportService.reject(data.id);
        message.success("ยกเลิกรายการสำเร็จ");
        refresh();
      } catch (error) {
        alertError(error);
      } finally {
        setLoading(false);
      }
    },
    [refresh]
  );

  return (
    <PageContainer className="withdraw-report-page">
      <FilterSection onSearch={onSearch} />
      <div className="mt-4 c-container">
        <LabelUnit
          className="text-lg lg:absolute top-9 left-6"
          label="ประวัติรายการถอน"
          number={total}
          suffix="รายการ"
          renderNumber={(number) => (
            <strong>
              <code>{number}</code>
            </strong>
          )}
          format
        />
        <WithdrawReportTable
          loading={loading}
          dataSource={statements}
          pagination={pagination}
          total={total}
          onChange={onTableChange}
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PageContainer>
  );
}

export default memo(WithdrawReportPage);
