import { Divider, Space, Spin, Table } from "antd";
import LabelUnit from "components/Text/LabelUnit";
import React, { memo, useEffect, useCallback } from "react";
import { AccountManagementService } from "services/account-management-service";
import { alertError } from "utils/error-util";
import { FcInfo } from "react-icons/fc";
import { AiOutlineHistory } from "react-icons/ai";
import { nanoid } from "@reduxjs/toolkit";
import {
  useBalance,
  useLoading,
  usePaginationTransactions,
  useSetBalance,
  useSetLoading,
  useSetOpenWithdrawHistoriesModal,
  useSetOpenWithdrawModal,
  useSetPaginationTransactions,
  useSetTransactions,
  useTransactions,
  useViewAccountId,
} from "./store";
import { ITransaction } from "types/account";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import dayjs from "dayjs";
import { formatNumber } from "utils/format-util";
import PopoverTransDetail from "./PopoverTransDetail";
import classNames from "classnames";
import WithdrawModal from "./WithdrawModal";
import ButtonIcon from "components/ButtonIcon";
import { BiTransfer } from "react-icons/bi";
import WithdrawHistoriesModal from "./WithdrawHistoriesModal";

function Statements() {
  const accountId = useViewAccountId();
  const loading = useLoading();
  const balance = useBalance();
  const transactions = useTransactions();
  const setBalance = useSetBalance();
  const setLoading = useSetLoading();
  const setTransactions = useSetTransactions();
  const pagination = usePaginationTransactions();
  const setPagination = useSetPaginationTransactions();
  const setOpenWithdrawModal = useSetOpenWithdrawModal();
  const setOpenWithdrawHistoriesModal = useSetOpenWithdrawHistoriesModal();

  useEffect(() => {
    if (accountId) {
      (async () => {
        try {
          setLoading(true);
          const payload = {
            accountId,
            transaction: true,
          };
          const response = await AccountManagementService.fetchTransactions(payload);
          const { balance, transactions } = response.data.result;
          transactions.forEach((transaction) => {
            transaction.id = nanoid(8);
          });
          setBalance(balance);
          setTransactions(transactions);
        } catch (error) {
          alertError(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [accountId]);

  const columns: ColumnsType<ITransaction> = [
    {
      title: "#",
      key: "__no__",
      width: 50,
      align: "center",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "ข้อความ",
      dataIndex: "description",
      render: (description, record) => {
        return (
          <Space align="start">
            <span>{description}</span>
            {record.kbankRef && (
              <div className="w-[18px]">
                <PopoverTransDetail
                  kbankRef={record.kbankRef}
                  detail={record.kbankTransDetail}
                  accountId={accountId as string}
                >
                  <span className="cursor-pointer" title="คลิกเพื่อดึงข้อมูลเพิ่มเติม">
                    <FcInfo size={18} />
                  </span>
                </PopoverTransDetail>
              </div>
            )}
          </Space>
        );
      },
    },
    {
      title: "ค่าธรรมเนียม (บาท)",
      dataIndex: "fee",
      align: "right",
      width: 150,
      render: (fee) => (
        <label className={classNames("text-base", { "text-slate-300": !fee })}>{formatNumber(fee, 2)}</label>
      ),
    },
    {
      title: "ช่องทาง",
      dataIndex: "channel",
      width: 200,
    },
    {
      title: "เวลา",
      dataIndex: "transactionTime",
      align: "center",
      width: 170,
      render: (transactionTime) => dayjs(transactionTime).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "จำนวน (บาท)",
      dataIndex: "amount",
      align: "right",
      width: 150,
      render: (amount, record) => {
        const sign = record.debit ? "-" : "+";
        const color = record.debit ? "#ff4d4f" : "#32c790";
        return (
          <label className="text-base" style={{ color }}>
            {sign}
            {formatNumber(amount, 2)}
          </label>
        );
      },
    },
  ];

  const onTableChange = useCallback((pagination: TablePaginationConfig) => {
    setPagination(pagination);
  }, []);

  return (
    <div className="c-container">
      <Spin spinning={loading}>
        <div className="flex justify-between">
          <LabelUnit
            className="text-lg"
            label="เงินคงเหลือในบัญชี"
            number={balance}
            suffix="บาท"
            renderNumber={(number) => (
              <strong>
                <code>{number}</code>
              </strong>
            )}
            format
          />
          <div className="flex gap-3">
            <ButtonIcon icon={<BiTransfer size={16} />} type="primary" ghost onClick={() => setOpenWithdrawModal(true)}>
              โอนเงินออก
            </ButtonIcon>
            <ButtonIcon icon={<AiOutlineHistory size={16} />} onClick={() => setOpenWithdrawHistoriesModal(true)}>
              ประวัติการโอนเงินออก
            </ButtonIcon>
          </div>
        </div>
        <Divider className="!mt-2 !mb-1" />
        <Table
          className="c-table header-center"
          rowKey="id"
          columns={columns}
          dataSource={transactions}
          pagination={pagination}
          onChange={onTableChange}
          scroll={{
            x: 1246,
          }}
          sticky
        />
      </Spin>

      <WithdrawModal accountId={accountId} />
      <WithdrawHistoriesModal accountId={accountId} />
    </div>
  );
}

export default memo(Statements);
