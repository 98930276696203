import { DeleteFilled } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table } from "antd";
import { ColumnsType, TablePaginationConfig, TableProps } from "antd/es/table";
import TagStatus from "components/TagStatus";
import { ProcessStatus } from "const";
import dayjs from "dayjs";
import React, { memo } from "react";
import { IProcessStatus } from "types";
import { WithdrawReport } from "types/withdraw-report";
import { formatNumber } from "utils/format-util";
import { rowIndex } from "utils/pagination-util";
import { copyTextToClipBoard } from "utils/text-util";

type WithdrawReportTableProps = TableProps<WithdrawReport> & {
  total: number;
  onApprove: (data: WithdrawReport) => void;
  onReject: (data: WithdrawReport) => void;
};

function WithdrawReportTable({ total, pagination, onApprove, onReject, ...props }: WithdrawReportTableProps) {
  const _pagination = pagination as TablePaginationConfig;
  const areThereAnyPendingData = !!props.dataSource?.some((data) => data.status === ProcessStatus.Pending);

  const columns: ColumnsType<WithdrawReport> = [
    {
      title: "#",
      key: "__no__",
      width: 50,
      align: "center",
      render: (_, __, index: number) => rowIndex(_pagination.current, _pagination.pageSize, index),
    },
    {
      title: "Transaction ID",
      dataIndex: "id",
      width: 170,
      ellipsis: true,
      render: (id) => (
        <span
          className="cursor-pointer hover:underline hover:underline-offset-2"
          onClick={() => copyTextToClipBoard(id)}
        >
          {id}
        </span>
      ),
    },
    {
      title: "รหัสผู้ใช้งาน",
      dataIndex: "playerName",
      width: 200,
    },
    {
      title: "บัญชี",
      key: "__user-account__",
      align: "center",
      width: 180,
      render: (data: WithdrawReport) => {
        return (
          <Space direction="vertical" align="center">
            <span>{data.withdrawAccountBank}</span>
            <span>{data.withdrawAccountNumber}</span>
          </Space>
        );
      },
    },
    {
      title: "วันที่/เวลา",
      dataIndex: "createDate",
      align: "center",
      width: 130,
      render: (createDate: Date) => {
        const [date, time] = dayjs(createDate).format("DD/MM/YYYY HH:mm:ss").split(" ");
        return <span style={{ whiteSpace: "pre-line" }}>{`${date}\n${time}`}</span>;
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      align: "center",
      width: 130,
      render: (status: IProcessStatus) => <TagStatus status={status} />,
    },
    {
      title: "ยอดเงิน",
      dataIndex: "amount",
      align: "right",
      width: 120,
      fixed: areThereAnyPendingData ? "right" : undefined,
      render: (amount: number) => formatNumber(amount, 2),
    },
    {
      key: "__actions__",
      align: "center",
      width: 140,
      fixed: areThereAnyPendingData ? "right" : undefined,
      render: (row) => {
        return (
          row.status === "P" && (
            <Space size={15}>
              <Button className="w-[55px]" type="primary" onClick={() => onApprove(row)} size="small">
                ยืนยัน
              </Button>
              <Popconfirm
                title="ยืนยันการยกเลิกรายการ"
                icon={<DeleteFilled style={{ color: "#ff4d4f" }} />}
                onConfirm={() => onReject(row)}
                okText="ยืนยัน"
                cancelText="ยกเลิก"
                okButtonProps={{ danger: true }}
              >
                <Button className="w-[55px]" type="primary" size="small" danger>
                  ยกเลิก
                </Button>
              </Popconfirm>
            </Space>
          )
        );
      },
    },
  ];

  if (!areThereAnyPendingData) {
    columns.pop();
  }

  const columnWidth = columns.reduce((acc: number, column) => acc + (column.width as number), 0);

  return (
    <Table rowKey="id" columns={columns} pagination={{ ...pagination, total }} scroll={{ x: columnWidth }} {...props} />
  );
}

export default memo(WithdrawReportTable);
