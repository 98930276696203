import { defaultPagination } from "const";
import create from "zustand";
import update from "immutability-helper";
import { Pagination } from "types";
import { TablePaginationConfig } from "antd/es/table";
import { PromotionHistory, PromotionHistoryStatus } from "types/promotion";

type StoreState = {
  loading: boolean;
  filterVisible: boolean;
  histories: PromotionHistory[];
  pagination: Pagination;
  total: number;
  editHistoryId: string[];
  savingEditHistoryId: string[];
  setLoading: (loading: boolean) => void;
  setFilterVisible: (filterVisible: boolean) => void;
  setHistories: (histories: PromotionHistory[]) => void;
  setPagination: (pagination: Partial<TablePaginationConfig>) => void;
  setTotal: (total: number) => void;
  addEditHistoryId: (editHistoryId: string) => void;
  removeEditHistoryId: (editHistoryId: string) => void;
  clearEditHistoryId: () => void;
  addSavingEditHistoryId: (id: string) => void;
  removeSavingEditHistoryId: (id: string) => void;
  clearSavingEditHistoryId: () => void;
  updatePromotionStatus: (id: string, status: PromotionHistoryStatus) => void;
  updateEdittingPromotionStatus: (id: string, status: PromotionHistoryStatus) => void;
};

const useStore = create<StoreState>((set) => ({
  loading: false,
  filterVisible: true,
  histories: [],
  pagination: { ...defaultPagination },
  total: 0,
  editHistoryId: [],
  savingEditHistoryId: [],
  setLoading: (loading: boolean) => set({ loading }),
  setFilterVisible: (filterVisible: boolean) => set({ filterVisible }),
  setHistories: (histories: PromotionHistory[]) => set({ histories }),
  setPagination: (pagination: Partial<TablePaginationConfig>) =>
    set((state) => update(state, { pagination: { $merge: pagination } })),
  setTotal: (total: number) => set({ total }),
  addEditHistoryId: (id: string) =>
    set(({ histories, editHistoryId }) => {
      const history = histories.find((history) => history.id === id);
      if (history) {
        history.editingStatus = history?.status;
      }
      return { histories: [...histories], editHistoryId: [...editHistoryId, id] };
    }),
  removeEditHistoryId: (id: string) =>
    set(({ editHistoryId }) => {
      return {
        editHistoryId: editHistoryId.filter((_id) => _id !== id),
      };
    }),
  clearEditHistoryId: () => set({ editHistoryId: [] }),
  addSavingEditHistoryId: (id: string) =>
    set(({ savingEditHistoryId }) => ({ savingEditHistoryId: [...savingEditHistoryId, id] })),
  removeSavingEditHistoryId: (id: string) =>
    set(({ savingEditHistoryId }) => {
      return {
        savingEditHistoryId: savingEditHistoryId.filter((_id) => _id !== id),
      };
    }),
  clearSavingEditHistoryId: () => set({ savingEditHistoryId: [] }),
  updatePromotionStatus: (id: string, status: PromotionHistoryStatus) =>
    set(({ histories }) => {
      const history = histories.find((history) => history.id === id);
      if (history) {
        history.status = status;
      }
      return {
        histories: [...histories],
      };
    }),
  updateEdittingPromotionStatus: (id: string, status: PromotionHistoryStatus) =>
    set(({ histories }) => {
      const history = histories.find((history) => history.id === id);
      if (history) {
        history.editingStatus = status;
      }
      return {
        histories: [...histories],
      };
    }),
}));

export const useLoading = () => useStore((state: StoreState) => state.loading);
export const useFilterVisible = () => useStore((state: StoreState) => state.filterVisible);
export const useHistories = () => useStore((state: StoreState) => state.histories);
export const usePagination = () => useStore((state: StoreState) => state.pagination);
export const useTotal = () => useStore((state: StoreState) => state.total);
export const useEditHistoryId = () => useStore((state: StoreState) => state.editHistoryId);
export const useSavingEditHistoryId = () => useStore((state: StoreState) => state.savingEditHistoryId);

export const useSetLoading = () => useStore((state: StoreState) => state.setLoading);
export const useSetFilterVisible = () => useStore((state: StoreState) => state.setFilterVisible);
export const useSetHistories = () => useStore((state: StoreState) => state.setHistories);
export const useSetPagination = () => useStore((state: StoreState) => state.setPagination);
export const useSetTotal = () => useStore((state: StoreState) => state.setTotal);
export const useAddEditHistoryId = () => useStore((state: StoreState) => state.addEditHistoryId);
export const useRemoveEditHistoryId = () => useStore((state: StoreState) => state.removeEditHistoryId);
export const useClearEditHistoryId = () => useStore((state: StoreState) => state.clearEditHistoryId);
export const useAddSavingEditHistoryId = () => useStore((state: StoreState) => state.addSavingEditHistoryId);
export const useRemoveSavingEditHistoryId = () => useStore((state: StoreState) => state.removeSavingEditHistoryId);
export const useClearSavingEditHistoryId = () => useStore((state: StoreState) => state.clearSavingEditHistoryId);
export const useUpdatePromotionStatus = () => useStore((state: StoreState) => state.updatePromotionStatus);
export const useUpdateEdittingPromotionStatus = () =>
  useStore((state: StoreState) => state.updateEdittingPromotionStatus);
