import { Tag, TagProps } from "antd";
import { AccountStatusDesc } from "const";
import React from "react";
import { IAccountStatus } from "types/account";

type TagAccountStatusProps = TagProps & {
  status: IAccountStatus;
};

function TagAccountStatus({ status, ...props }: TagAccountStatusProps) {
  return (
    <Tag color={color[status]} {...props}>
      {AccountStatusDesc[status]}
    </Tag>
  );
}

const color: Record<IAccountStatus, string> = {
  A: "#87d068",
  I: "#50535c",
};

export default TagAccountStatus;
