import create from "zustand";
import cloneDeep from "lodash/cloneDeep";
import update from "immutability-helper";
import { IAdmin } from "types/admin";
import { EditingMode, Pagination } from "types";
import { defaultPagination } from "const";
import { TablePaginationConfig } from "antd";

const _defaultPagination: Pagination = {
  ...defaultPagination,
  position: ["bottomRight"],
};

type State = {
  loading: boolean;
  loadingAdminDetail: boolean;
  adminList: IAdmin[];
  pagination: Pagination;
  visibleModal: boolean;
  mode?: EditingMode;
  editingAdmin?: IAdmin;
};

type Actions = {
  setLoading: (loading: boolean) => void;
  setLoadingAdminDetail: (loadingAdminDetail: boolean) => void;
  setAdminList: (adminList: IAdmin[]) => void;
  setPagination: (pagination: Partial<TablePaginationConfig>) => void;
  setVisibleModal: (visibleModal: boolean) => void;
  openAdminModal: (mode: EditingMode, admin?: IAdmin) => void;
  closeAdminModal: () => void;
  resetState: () => void;
};

const initialState: State = {
  loading: false,
  loadingAdminDetail: false,
  adminList: [],
  pagination: cloneDeep(_defaultPagination),
  visibleModal: false,
  mode: undefined,
};

type StoreState = State & Actions;

const useStore = create<StoreState>((set) => ({
  ...cloneDeep(initialState),
  setLoading: (loading: boolean) => set({ loading }),
  setLoadingAdminDetail: (loadingAdminDetail: boolean) => set({ loadingAdminDetail }),
  setAdminList: (adminList: IAdmin[]) => set({ adminList }),
  setPagination: (pagination: Partial<TablePaginationConfig>) =>
    set((state) => ({
      pagination: update(state.pagination, { $merge: pagination }),
    })),
  setVisibleModal: (visibleModal: boolean) => set({ visibleModal }),
  openAdminModal: (mode: EditingMode, editingAdmin?: IAdmin) => set({ visibleModal: true, mode, editingAdmin }),
  closeAdminModal: () => set({ visibleModal: false, mode: undefined, editingAdmin: undefined }),
  resetState: () => set(cloneDeep(initialState)),
}));

export const useLoading = () => useStore((state: StoreState) => state.loading);
export const useLoadingAdminDetail = () => useStore((state: StoreState) => state.loadingAdminDetail);
export const useAdminList = () => useStore((state: StoreState) => state.adminList);
export const usePagination = () => useStore((state: StoreState) => state.pagination);
export const useVisibleModal = () => useStore((state: StoreState) => state.visibleModal);
export const useEditingMode = () => useStore((state: StoreState) => state.mode);
export const useEditingAdmin = () => useStore((state: StoreState) => state.editingAdmin);

export const useSetLoading = () => useStore((state: StoreState) => state.setLoading);
export const useSetLoadingAdminDetail = () => useStore((state: StoreState) => state.setLoadingAdminDetail);
export const useSetAdminList = () => useStore((state: StoreState) => state.setAdminList);
export const useSetPagination = () => useStore((state: StoreState) => state.setPagination);
export const useSetVisibleModal = () => useStore((state: StoreState) => state.setVisibleModal);
export const useOpenAdminModal = () => useStore((state: StoreState) => state.openAdminModal);
export const useCloseAdminModal = () => useStore((state: StoreState) => state.closeAdminModal);
export const useResetState = () => useStore((state: StoreState) => state.resetState);
