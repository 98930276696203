import React, { useCallback } from "react";
import { push } from "connected-react-router";
import { Sidebar, Menu, MenuItem, SubMenu, sidebarClasses, menuClasses } from "react-pro-sidebar";
// import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import { IMenu, menus } from "const/menus";
import { AuthenticationSelector } from "redux/selectors/auth";
import styled from "styled-components";
import { Grid } from "antd";

function MenuSilder() {
  const dispatch = useDispatch();
  const location = useLocation();
  const breakPoint = Grid.useBreakpoint();
  const screenRoles = useSelector(AuthenticationSelector.screenRoles);

  const go = useCallback((path: string) => void dispatch(push(path)), [dispatch]);

  const keyMenu = (menu: IMenu) => menu.path ?? menu.label;

  const renderMenus = (menu: IMenu) => {
    if (menu.subMenu) {
      const noMenuShow = menu.menus?.every((menu) => !screenRoles.includes(menu.code));
      if (noMenuShow) return null;
      const active = !!menu.menus?.some((menu) => !!matchPath(location.pathname, { path: menu.path }));
      return (
        <SubMenu key={keyMenu(menu)} active={active} label={menu.label} title={menu.label} icon={menu.icon}>
          {menu.menus?.map(renderMenus)}
        </SubMenu>
      );
    } else {
      return renderMenu(menu);
    }
  };

  const renderMenu = (menu: IMenu) => {
    const active = !!matchPath(location.pathname, { path: menu.path });
    if (screenRoles.includes(menu.code)) {
      return (
        <MenuItem key={keyMenu(menu)} active={active} icon={menu.icon} onClick={() => menu.path && go(menu.path)}>
          {menu.label}
        </MenuItem>
      );
    }

    return null;
  };

  return (
    <SidebarStyled
      defaultCollapsed={!breakPoint.lg}
      width="240px"
      backgroundColor="#1d1d1d"
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: "#1d1d1d",
          minHeight: "calc(100vh - 64px)",
        },
        [`.${menuClasses.label}`]: {
          color: "white",
        },
        [`.${menuClasses.icon}`]: {
          color: "white",
        },
        [`.${menuClasses.button}`]: {
          color: "white",
          backgroundColor: "#1d1d1d",
        },
        [`.${menuClasses.active}`]: {
          color: "red",
          backgroundColor: "red",
        },
      }}
    >
      <Menu>{menus.map(renderMenus)}</Menu>
    </SidebarStyled>
  );
}

export default MenuSilder;

const SidebarStyled = styled(Sidebar)`
  li.ps-active {
    background-color: #3c3c3c;

    & > a > span {
      color: #57d4a2;
    }
  }

  a.ps-menu-button:hover {
    background-color: #3c3c3c;
  }
`;
