import React from "react";
import { FaSms, FaMoneyBillWave } from "react-icons/fa";
import { BiTransfer } from "react-icons/bi";
import { BsBank2 } from "react-icons/bs";
import { RiBankCardFill } from "react-icons/ri";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { AiFillTrophy, AiFillGift, AiTwotoneTags } from "react-icons/ai";

export type IMenu = {
  code: string;
  label: string;
  icon?: React.ReactNode;
  path?: string;
  subMenu?: boolean;
  menus?: IMenu[];
};

export const menus: IMenu[] = [
  {
    code: "g0001",
    subMenu: true,
    label: "รายงาน",
    icon: <BiTransfer />,
    menus: [
      {
        code: "s0001",
        label: "รายงานบัญชีฝาก",
        icon: <BsBank2 />,
        path: "/report/deposit",
      },
      {
        code: "s0002",
        label: "รายงานบัญชีถอน",
        icon: <FaMoneyBillWave />,
        path: "/report/withdraw",
      },
    ],
  },
  {
    code: "g0002",
    subMenu: true,
    label: "โปรโมชั่น",
    icon: <AiFillTrophy />,
    menus: [
      {
        code: "s0006",
        label: "ตั้งค่าโปรโมชั่น",
        icon: <AiFillGift />,
        path: "/promotion/setup",
      },
      {
        code: "s0007",
        label: "รายการโปรโมชั่น",
        icon: <AiTwotoneTags />,
        path: "/promotion/histories",
      },
    ],
  },
  {
    code: "s0003",
    label: "จัดการบัญชี",
    icon: <RiBankCardFill />,
    path: "/account-management",
  },
  {
    code: "s0004",
    label: "จัดการผู้ดูแล",
    icon: <MdOutlineAdminPanelSettings size={18} />,
    path: "/admin-management",
  },
  {
    code: "s0005",
    label: "ส่งข้อความ",
    icon: <FaSms />,
    path: "/sms",
  },
];
