import { Breadcrumb, BreadcrumbProps } from "antd";
import React, { memo } from "react";
import { useSetPageLevel } from "./store";
import compact from "lodash/compact";
import { IPages, PageLevel } from "./AccountManagementPage";

type AccountBreadcrumb = BreadcrumbProps & {
  pages: IPages;
  pageLevel: PageLevel;
};

function AccountBreadcrumb({ pages, pageLevel, ...props }: AccountBreadcrumb) {
  const setPageLevel = useSetPageLevel();

  const renderMenus = () => {
    return compact(
      Object.entries(pages).map(([level, label]) => {
        if (pageLevel >= +level) {
          return <Breadcrumb.Item key={level} onClick={() => setPageLevel(+level as PageLevel)}>{label}</Breadcrumb.Item>;
        }
        return null;
      })
    );
  };

  return (
    <div className="pl-2 cursor-pointer">
      <Breadcrumb className="underline underline-offset-2" {...props}>
        {renderMenus()}
      </Breadcrumb>
    </div>
  );
}

export default memo(AccountBreadcrumb);
