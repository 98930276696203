export function isEmpty(val: string | null | undefined | Array<string>) {
  return val === null || val === undefined || val === "" || (Array.isArray(val) && val.length === 0);
}

export function escapeRegExp(text: string | null | undefined) {
  if (!text) return;
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const resolve = (data: object, ...fields: string[]) => {
  if (!data) return;
  if (data && fields && isEmpty(fields[0])) return data;

  return fields
    .reduce((results: string[], field: string) => {
      const includeDot = field && field.indexOf(".") > -1;
      return includeDot ? [...results, ...field.split(".")] : [...results, field];
    }, [])
    .reduce((result: object | undefined | null, field: string) => {
      return result ? result[field as keyof typeof result] : undefined;
    }, data);
};
