import { createSlice } from '@reduxjs/toolkit'
import { SmsActivity } from 'types';

type InitialState = {
  loading: boolean;
  credit: number;
  smsActivities: SmsActivity[];
  error: string | null;
}

const initialState: InitialState = {
  loading: false,
  credit: 0,
  smsActivities: [],
  error: null,
}

const slice = createSlice({
  initialState,
  name: 'Sms',
  reducers: {
    getSmsActivities: (state) => {
      state.loading = true;
    },
    getSmsActivitiesSuccess: (state, action) => {
      state.loading = false;
      state.credit = action.payload.credit;
      state.smsActivities = action.payload.smsActivities;
    },
    getSmsActivitiesFailure: (state, action) => {
      state.loading = false;
      state.smsActivities = [];
      state.error = action.payload;
    },
  }
});

export const { getSmsActivities, getSmsActivitiesSuccess, getSmsActivitiesFailure } = slice.actions;

export default slice;
