import React, { useEffect } from "react";
import MenuSilder from "./container/MenuSilder";
import { BackTop, Layout } from "antd";
import HeaderBar from "./container/HeaderBar";
import styled from "styled-components";
import Contents from "./container/Contents";
import { useToken } from "hooks";
import { useDispatch } from "react-redux";
import { initialStore } from "redux/reducers/auth";
import { ProSidebarProvider } from "react-pro-sidebar";

function Main() {
  const dispatch = useDispatch();
  const token = useToken();

  useEffect(() => {
    if (!token) dispatch(initialStore());
  }, [dispatch, token]);

  return (
    <ProSidebarProvider>
      <Layout className="h-screen">
        <HeaderStyled className="header">
          <HeaderBar token={token} />
        </HeaderStyled>
        <Layout>
          {token && (
            <div className="flex">
              <MenuSilder />
              <Layout className="container-content max-h-[calc(100vh-64px)] overflow-auto">
                <Layout.Content>
                  <Contents />
                </Layout.Content>
                <BackTop
                  visibilityHeight={800}
                  target={() => document.querySelector(".container-content") as HTMLElement}
                />
              </Layout>
            </div>
          )}
        </Layout>
      </Layout>
    </ProSidebarProvider>
  );
}

export default Main;

const HeaderStyled = styled(Layout.Header)`
  background: #1d1d1d;
  padding-left: 20px !important;
  padding-right: 50px !important;
`;
