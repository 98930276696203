import React, { memo, useState } from "react";
import { Popover, Input } from "antd";
import { MdContactPhone } from "react-icons/md";
import ButtonIcon from "components/ButtonIcon";
import { displayTextFilter } from "utils/text-util";

type PopoverPhoneListProps = {
  phoneList: string[];
};

function PopoverPhoneList({ phoneList }: PopoverPhoneListProps) {
  const [phoneFilter, setPhoneFilter] = useState<string>("");
  const renderContent = () => {
    return (
      <div className="h-[300px] overflow-y-auto overflow-x-hidden">
        <div className="phone-filter">
          <Input
            value={phoneFilter}
            onChange={(evt) => setPhoneFilter(evt.target.value)}
            placeholder="ค้นหาหมายเลข"
            allowClear
          />
        </div>
        <div className="phone-list mt-2 pl-1">
          {phoneList
            .filter((phone) => phone.includes(phoneFilter))
            .map((phone) => (
              <div key={phone} className="tracking-wide">
                {displayTextFilter(phone, phoneFilter)}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <Popover
      content={renderContent()}
      title="หมายเลขที่ส่ง SMS"
      trigger="click"
      placement="rightTop"
      destroyTooltipOnHide
    >
      <ButtonIcon type="primary" icon={<MdContactPhone size={20} />} shape="round" ghost></ButtonIcon>
    </Popover>
  );
}

export default memo(PopoverPhoneList);
