import React from "react";
import { Form, InputNumber, Select, Space } from "antd";
import { durationDayOptions, durationOptions } from "./const";

export const renderDurationSection = () => {
  return (
    <>
      <Space size={16}>
        <Form.Item name="duration" label="รยะเวลาคำนวณ" rules={[{ required: true, message: "รยะเวลาคำนวณ" }]}>
          <Select options={durationOptions} style={{ width: 120 }} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const duration = getFieldValue("duration");
            if (!duration || duration === "day") return;

            if (duration === "week") {
              return (
                <Form.Item name="atDay" label="วันที่คำนวณ" rules={[{ required: true, message: "ระบุวันที่คำนวณ" }]}>
                  <Select options={durationDayOptions} style={{ width: 120 }} />
                </Form.Item>
              );
            }

            if (duration === "month") {
              return (
                <Form.Item name="atDay" label="วันที่คำนวณ" rules={[{ required: true, message: "ระบุวันที่คำนวณ" }]}>
                  <InputNumber step={1} min={1} max={31} placeholder="1 - 31" style={{ width: 100 }} />
                </Form.Item>
              );
            }

            return;
          }}
        </Form.Item>
      </Space>
    </>
  );
};
