import { Tag, TagProps } from "antd";
import { AccountStatusDesc, IAdminStatus } from "const";
import React from "react";

type TagAdminStatusProps = TagProps & {
  status: IAdminStatus;
};

function TagAdminStatus({ status, ...props }: TagAdminStatusProps) {
  return (
    <Tag color={color[status]} {...props} style={{ marginRight: 0 }}>
      {AccountStatusDesc[status]}
    </Tag>
  );
}

const color: Record<IAdminStatus, string> = {
  A: "#87d068",
  I: "#50535c",
};

export default TagAdminStatus;
