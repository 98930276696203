import React from "react";
import classnames from "classnames";
import { capitalize } from "utils/common-util";
import { ListMethod } from "../type";

type PhoneMethodProps = {
  label: string;
  method: ListMethod;
  active: boolean;
  onSelect: (method: ListMethod) => void;
};

function PhoneMethod({ label, method, active, onSelect }: PhoneMethodProps) {
  return (
    <li
      className={classnames("underline underline-offset-2 cursor-pointer", {
        "text-primary decoration-primary": active,
      })}
      onClick={() => onSelect(method)}
    >
      {capitalize(label)}
    </li>
  );
}

export default PhoneMethod;
