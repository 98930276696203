import { Button, Space } from "antd";
import { ButtonProps } from 'antd/es/button';
import React from "react";

interface ButtonIconProps extends ButtonProps {
  icon: React.ReactNode;
  children?: React.ReactNode;
}

function ButtonIcon({ icon, children, ...props }: ButtonIconProps) {
  return (
    <Button {...props}>
      <Space>
        {icon}
        {children && <span>{children}</span>}
      </Space>
    </Button>
  );
}

export default ButtonIcon;
