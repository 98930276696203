import { Table } from "antd";
import React, { memo } from "react";
import { SmsActivity } from "types";
import type { ColumnsType } from "antd/es/table";
import PopoverPhoneList from "./PopoverPhoneList";
import dayjs from "dayjs";
import { formatNumber } from "utils/format-util";

type ISmsTableProps = {
  dataSource: SmsActivity[];
};

function SmsTable(props: ISmsTableProps) {
  const { dataSource } = props;

  const columns: ColumnsType<SmsActivity> = [
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "phoneList",
      width: 150,
      align: "center",
      render: (phoneList) => {
        return <PopoverPhoneList phoneList={phoneList} />;
      },
    },
    {
      title: "ข้อความ",
      dataIndex: "message",
    },
    {
      title: "ชื่อผู้ส่ง",
      dataIndex: "senderName",
      width: 230,
    },
    {
      title: "เครดิตที่ใช้",
      dataIndex: "usedCredit",
      width: 150,
      align: "right",
      render: (usedCredit) => formatNumber(usedCredit, 2),
    },
    {
      title: "โดย",
      dataIndex: "createBy",
      width: 150,
    },
    {
      title: "เวลา",
      dataIndex: "createDate",
      width: 150,
      align: "center",
      render: (createDate) => dayjs(createDate).format("DD/MM/YYYY HH:mm"),
    },
  ];

  return (
    <div className="mt-3 mx-3">
      <Table
        rowKey="id"
        className="c-table header-center"
        columns={columns}
        dataSource={dataSource}
        scroll={{
          x: 1246,
        }}
        bordered
        size="small"
      />
    </div>
  );
}

export default memo(SmsTable);

// {
//     "result": {
//         "credit": 988,
//         "systemCredit": 990,
//         "details": [{
//                 "id": "632f9c0c35d80c3469220acf",
//                 "agent": "champag",
//                 "senderName": "BulkSMS.Ltd",
//                 "message": "555",
//                 "usedCredit": 2,
//                 "phoneList": ["0821613050", "0867972703"],
//                 "createDate": "2022-09-25T07:08:43.677",
//                 "createBy": "champag"
//             }, {
//                 "id": "632f42af35d80c3469220ace",
//                 "agent": "champag",
//                 "senderName": "BulkSMS.Ltd",
//                 "message": "ขอเทสหน่อย",
//                 "usedCredit": 2,
//                 "phoneList": ["0821613050", "0867972703"],
//                 "createDate": "2022-09-25T00:47:26.8",
//                 "createBy": "champag"
//             }, {
//                 "id": "632f2e1c35d80c3469220acd",
//                 "agent": "champag",
//                 "senderName": "BulkSMS.Ltd",
//                 "message": "ooooo",
//                 "usedCredit": 2,
//                 "phoneList": ["0821613050", "0867922703"],
//                 "createDate": "2022-09-24T23:19:39.459",
//                 "createBy": "champag"
//             }, {
//                 "id": "632f2c4135d80c3469220acc",
//                 "agent": "champag",
//                 "senderName": "BulkSMS.Ltd",
//                 "message": "hello sms",
//                 "usedCredit": 2,
//                 "phoneList": ["0821613050", "0867922703"],
//                 "createDate": "2022-09-24T23:11:44.857",
//                 "createBy": "champag"
//             }, {
//                 "id": "632f29b935d80c3469220acb",
//                 "agent": "champag",
//                 "senderName": "BulkSMS.Ltd",
//                 "message": "ทดสอบส่ง SMS",
//                 "usedCredit": 1,
//                 "phoneList": ["0821613050"],
//                 "createDate": "2022-09-24T23:00:57.798",
//                 "createBy": "champag"
//             }, {
//                 "id": "632c60bb35d80c3469220aca",
//                 "agent": "champag",
//                 "senderName": "BulkSMS.Ltd",
//                 "message": "ทดสอบ otp ครั้งที่ 5",
//                 "usedCredit": 1,
//                 "phoneList": ["0821613050"],
//                 "createDate": "2022-09-22T20:18:51.244",
//                 "createBy": "champag"
//             }, {
//                 "id": "6324bd8d8b839619b6d1ffbd",
//                 "agent": "champag",
//                 "senderName": "BulkSMS.Ltd",
//                 "message": "ทดสอบ otp ครั้งที่ 4",
//                 "usedCredit": 1,
//                 "phoneList": ["0821613050"],
//                 "createDate": "2022-09-16T18:16:45.437",
//                 "createBy": "champag"
//             }, {
//                 "id": "6324bd1b563e0b64df8423a8",
//                 "agent": "champag",
//                 "senderName": "BulkSMS.Ltd",
//                 "message": "ทดสอบ otp ครั้งที่ 3",
//                 "usedCredit": 0,
//                 "phoneList": [],
//                 "createDate": "2022-09-16T18:14:51.27",
//                 "createBy": "champag"
//             }, {
//                 "id": "6324b9c1ad83b42cd6c65115",
//                 "agent": "champag",
//                 "senderName": "BulkSMS.Ltd",
//                 "message": "ทดสอบ otp ครั้งที่ 2",
//                 "usedCredit": 1,
//                 "phoneList": ["66821613050"],
//                 "createDate": "2022-09-16T18:00:32.968",
//                 "createBy": "champag"
//             }, {
//                 "id": "6324b65b682a0e0395a3735c",
//                 "agent": "champag",
//                 "senderName": "BulkSMS.Ltd",
//                 "message": "ทดสอบ otp",
//                 "usedCredit": 1,
//                 "phoneList": ["66821613050"],
//                 "createDate": "2022-09-16T17:46:02.87",
//                 "createBy": "champag"
//             }
//         ]
//     },
//     "status": "0",
//     "message": "Success",
//     "timestamp": 1664527735636
// }
