import { Form, FormInstance, InputNumber, Switch } from "antd";
import { renderDurationSection } from "pages/promotions/helper";
import { PromotionAffType } from "types/promotion";
import React from "react";

interface PromotionAFFProps {
  form: FormInstance;
}

type FormValueChanged = Partial<PromotionAffType>;

function PromotionAFF({ form }: PromotionAFFProps) {
  const onValuesChange = (changedValues: FormValueChanged) => {
    if ("percentWinLoss" in changedValues && changedValues.percentWinLoss) {
      form.resetFields(["percentTurnOver"]);
    } else if ("percentTurnOver" in changedValues && changedValues.percentTurnOver) {
      form.resetFields(["percentWinLoss"]);
    }

    if ("duration" in changedValues) {
      if (changedValues.duration === "day") {
        form.setFieldValue("atDay", 0);
      } else {
        form.resetFields(["atDay"]);
      }
    }
  };

  return (
    <div className="promotion-aff pt-2">
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
        initialValues={{
          percentDeposit: 0,
          percentWinLoss: 0,
          percentTurnOver: 0,
          duration: "day",
          atDay: null,
          enable: true,
        }}
      >
        <Form.Item
          name="percentDeposit"
          label="ส่วนแบ่งจากการเติมเงิน ( % )"
          rules={[{ required: true, message: "ระบุส่วนแบ่งจากการเติมเงิน" }]}
        >
          <InputNumber step={0.1} min={0} max={100} style={{ width: 100 }} />
        </Form.Item>
        <Form.Item
          name="percentWinLoss"
          label="ส่วนแบ่งจากยอดเสีย ( % )"
          rules={[
            ({ getFieldValue }) => ({
              required: getFieldValue("percentTurnOver") === 0,
              message: "ระบุส่วนแบ่งจากยอดเสีย",
            }),
          ]}
        >
          <InputNumber step={0.1} min={0} max={100} style={{ width: 100 }} />
        </Form.Item>
        <Form.Item
          name="percentTurnOver"
          label="ส่วนแบ่งจากการทำยอดเทริน ( % )"
          rules={[
            ({ getFieldValue }) => ({
              required: getFieldValue("percentWinLoss") === 0,
              message: "ระบุส่วนแบ่งจากยอดเสีย",
            }),
          ]}
        >
          <InputNumber step={0.1} min={0} max={100} style={{ width: 100 }} />
        </Form.Item>

        {renderDurationSection()}

        <Form.Item name="enable" label="เปิด/ปิด โปรโมชั่นแนะนำเพื่อน" valuePropName="checked">
          <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
        </Form.Item>
      </Form>
    </div>
  );
}

export default PromotionAFF;
