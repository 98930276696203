import { Pagination } from "types";
import { IAccountType, IAccountStatus } from "types/account";
import { IProcessStatus as ProcessStatusType } from "types/process-status";

type Option<T> = {
  label: string;
  value: T;
};

export const ProcessStatus = {
  Success: "S",
  Pending: "P",
  Failure: "F",
  Reject: "R",
} as const;

export const processStatusOptions: Option<ProcessStatusType>[] = [
  { label: "สำเร็จ", value: "S" },
  { label: "รอดำเนินการ", value: "P" },
  { label: "ล้มเหลว", value: "F" },
  { label: "ยกเลิกรายการ", value: "R" },
];

export const defaultPagination: Pagination = {
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ["10", "25", "50", "100"],
  position: ["topRight", "bottomRight"],
};

//? Admin
export const AdminStatus = {
  Active: "A",
  Inactive: "I",
} as const;

export type IAdminStatus = typeof AdminStatus[keyof typeof AdminStatus];

export const AdminStatusDesc: Record<IAdminStatus, string> = {
  A: "เปิดใช้งานอยู่",
  I: "ไม่ได้ใช้งาน",
} as const;
//? End Admin

// Account //
export const AccountStatus = {
  Active: "A",
  Inactive: "I",
} as const;

export const AccountStatusDesc: Record<IAccountStatus, string> = {
  A: "เปิดใช้งานอยู่",
  I: "ไม่ได้ใช้งาน",
} as const;

export const AccountType = {
  Deposit: "deposit",
  Withdraw: "withdraw",
} as const;

export const AccountTypeDesc: Record<IAccountType, string> = {
  deposit: "บัญชีฝาก",
  withdraw: "บัญชีถอน",
} as const;
// End Account //
