import AccountManagementPage from "pages/account-management/AccountManagementPage";
import AdminPage from "pages/admin/AdminPage";
import DepositReportPage from "pages/deposit-report/DepositReportPage";
import PromotionHistoryPage from "pages/promotions/histories/PromotionListPage";
import PromotionSetupPage from "pages/promotions/setup/PromotionSetupPage";
import SmsPage from "pages/sms/SmsPage";
import WithdrawReportPage from "pages/withdraw-report/WithdrawReportPage";
import React from "react";
import { Route, Switch } from "react-router-dom";

function Contents() {
  return (
    <Switch>
      <Route path="/report/deposit" component={DepositReportPage} />
      <Route path="/report/withdraw" component={WithdrawReportPage} />
      <Route path="/account-management" component={AccountManagementPage} />
      <Route path="/admin-management" component={AdminPage} />
      <Route path="/sms" component={SmsPage} />
      <Route path="/promotion/setup" component={PromotionSetupPage} />
      <Route path="/promotion/histories" component={PromotionHistoryPage} />
    </Switch>
  );
}

export default Contents;
