import axios from "axios";
import store from "redux/store";
import { clearToken } from "redux/reducers/auth";

// axios.defaults.withCredentials = true

export const AxiosAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragme: "no-cache",
    Expires: "0",
  },
});

AxiosAPI.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

AxiosAPI.interceptors.response.use(
  (response) => {
    if (!(response.data instanceof Blob) && !(response?.data?.status === "0")) {
      return Promise.reject(new Error(response.data?.message));
    }
    return response;
  },
  async (error) => {
    const { response } = error;
    if (response.status === 401) {
      store.dispatch(clearToken());
      return Promise.reject({ message: "เซสชั่นหมดอายุ กรุณาเข้าระบบใหม่" });
    } else if (response.status === 500) {
      return Promise.reject(new Error("เกิดข้อผิดพลาด" + response.data?.message ? ` : ${response.data?.message}` : ""));
    }
    return Promise.reject({ message: "เกิดข้อผิดพลาด" });
  }
);
