import { LoadingOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import Field from "components/Form/Field";
import FilterContainer from "components/Page/FilterContainer";
import { processStatusOptions } from "const";
import React, { memo, useEffect, useState } from "react";
import { FilterFields } from "types/deposit-report";
import { IProcessStatus } from "types/process-status";
import { useDebounce } from "use-debounce";
import { useFilters, useFilterVisible, useSetFilters, useSetFilterVisible } from "./store";

type FilterSectionProps = {
  onSearch: (filters: FilterFields) => void;
};

function FilterSection({ onSearch }: FilterSectionProps) {
  const filterVisible = useFilterVisible();
  const setFilterVisible = useSetFilterVisible();
  const filters = useFilters();
  const setFilters = useSetFilters();
  const [playerName, setPlayerName] = useState(filters.playerName);
  const [playerNameDebounce] = useDebounce(playerName, 700);
  const waitingForUpdate = playerName !== playerNameDebounce;

  useEffect(() => {
    setFilters({ playerName: playerNameDebounce });
  }, [playerNameDebounce]);

  return (
    <FilterContainer
      filterVisible={filterVisible}
      onFilterVisibleChange={setFilterVisible}
      onSearch={() => onSearch(filters)}
    >
      <>
        <Field label="รหัสผู้ใช้งาน">
          <Input
            className="!w-60"
            value={playerName}
            placeholder="รหัสผู้ใช้งาน"
            onChange={(evt) => setPlayerName(evt.target.value)}
            suffix={waitingForUpdate ? <LoadingOutlined /> : null}
            allowClear={!waitingForUpdate}
          />
        </Field>
        <Field label="สถานะ">
          <Select
            className="w-36"
            showSearch
            placeholder="สถานะ"
            filterOption={(input, option) => (option?.label.toLowerCase().indexOf(input.toLowerCase()) ?? 0) >= 0}
            options={processStatusOptions}
            onChange={(status: IProcessStatus) => setFilters({ status })}
            allowClear
          />
        </Field>
      </>
    </FilterContainer>
  );
}

export default memo(FilterSection);
