import { Descriptions, Popover } from "antd";
import Loading from "components/Misc/Loading";
import Skeleton from "components/Misc/Skeleton";
import React, { memo } from "react";
import { AccountManagementService } from "services/account-management-service";
import { ITransactionKbankDetail } from "types/account";
import { alertError } from "utils/error-util";
import { useFetchingKbankDetail, useSetFetchingKbankDetail, useSetKbankTransDetail } from "./store";

type PopoverTransDetailProps = {
  accountId: string;
  kbankRef: string;
  detail?: ITransactionKbankDetail;
  children: React.ReactNode;
};

function PopoverTransDetail({ accountId, kbankRef, detail, children }: PopoverTransDetailProps) {
  const fetchingKbankDetail = useFetchingKbankDetail();
  const setFetchingKbankDetail = useSetFetchingKbankDetail();
  const setKbankTransDetail = useSetKbankTransDetail();

  const getKbankTransDetail = async () => {
    console.log("detail:", detail);
    if (!detail) {
      try {
        setFetchingKbankDetail(kbankRef);
        const response = await AccountManagementService.getKbankTransDetail({
          accountId,
          ref: kbankRef,
        });
        setKbankTransDetail(response.data.result);
      } catch (error) {
        alertError(error);
      } finally {
        setFetchingKbankDetail(undefined);
      }
    }
  };

  return (
    <Popover
      title={null}
      content={
        <div>
          <Descriptions title="ข้อมูลรายการ" column={1} bordered>
            <Descriptions.Item label="ชื่อบัญชี">
              <Skeleton text={detail?.toAccountName} />
            </Descriptions.Item>
            <Descriptions.Item label="เลขบัญชี">
              <Skeleton text={detail?.toAccountNo} />
            </Descriptions.Item>
            <Descriptions.Item label="ธนาคาร">
              <Skeleton text={detail?.toBankName} />
            </Descriptions.Item>
          </Descriptions>
        </div>
      }
      trigger="click"
      placement="rightTop"
    >
      {fetchingKbankDetail === kbankRef ? <Loading /> : <div onClick={getKbankTransDetail}>{children}</div>}
    </Popover>
  );
}

export default memo(PopoverTransDetail);


