import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import Authentication from "./auth"
import Sms from "./sms"

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    [Authentication.name]: Authentication.reducer,
    [Sms.name]: Sms.reducer,
  });

export default createRootReducer;
