import { Button, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/es/table";
import ButtonIcon from "components/ButtonIcon";
import Title from "components/Page/Title";
import TagAdminStatus from "components/Tag/TagAdminStatus";
import { IAdminStatus } from "const";
import PageContainer from "pages/container/PageContainer";
import React, { memo, useEffect, useCallback } from "react";
import { AdminService } from "services/admin-service";
import { IAdmin } from "types/admin";
import { alertError } from "utils/error-util";
import { rowIndex } from "utils/pagination-util";
import AddEditAdminModal from "./AddEditAdminModal";
import { MdPersonAddAlt1 } from "react-icons/md";
import {
  useAdminList,
  useLoading,
  useOpenAdminModal,
  usePagination,
  useSetAdminList,
  useSetLoading,
  useSetPagination,
} from "./store";

function AdminPage() {
  const loading = useLoading();
  const setLoading = useSetLoading();
  const adminList = useAdminList();
  const setAdminList = useSetAdminList();
  const pagination = usePagination();
  const setPagination = useSetPagination();
  const openAdminModal = useOpenAdminModal();

  const fetchAdmins = useCallback(async () => {
    const controller = new AbortController();
    (async () => {
      try {
        setLoading(true);
        setAdminList(await AdminService.fetchAdminList(controller.signal));
      } catch (error) {
        alertError(error);
      } finally {
        if (!controller.signal.aborted) {
          setLoading(false);
        }
      }
    })();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    fetchAdmins();
  }, [fetchAdmins]);

  const onTableChange = useCallback((pagination: TablePaginationConfig) => {
    setPagination(pagination);
  }, []);

  const columns: ColumnsType<IAdmin> = [
    {
      title: "#",
      key: "__no__",
      width: 50,
      align: "center",
      render: (_, __, index: number) => {
        const _pagination = pagination as TablePaginationConfig;
        return rowIndex(_pagination.current, _pagination.pageSize, index);
      },
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "ชื่อ",
      key: "__fullname__",
      render: (admin: IAdmin) => `${admin.firstName ?? ""} ${admin.lastName ?? ""}`.trim(),
    },
    {
      title: "บทบาท",
      dataIndex: "role",
    },
    {
      title: "ไซต์",
      dataIndex: "site",
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      width: 100,
      align: "center",
      fixed: "right",
      render: (status: IAdminStatus) => <TagAdminStatus status={status} />,
    },
    {
      width: 100,
      align: "center",
      fixed: "right",
      render: (admin: IAdmin) => {
        return (
          <Button type="primary" onClick={() => openAdminModal("edit", admin)} size="small">
            เรียกดู/แก้ไข
          </Button>
        );
      },
    },
  ];

  return (
    <PageContainer className="admin-page">
      <Title className="mb-0" level={4}>
        รายชื่อผู้ดูแล
      </Title>
      <div className="mt-4">
        <div className="flex justify-end">
          <ButtonIcon type="primary" icon={<MdPersonAddAlt1 />} onClick={() => openAdminModal("add")}>
            เพิ่มผู้ดูแล
          </ButtonIcon>
        </div>
        <Table
          className="mt-4"
          rowKey="username"
          loading={loading}
          columns={columns}
          dataSource={adminList}
          pagination={pagination}
          onChange={onTableChange}
          scroll={{
            x: 1246,
          }}
          size="small"
          sticky
        />
      </div>

      <AddEditAdminModal fetchAdmins={fetchAdmins} />
    </PageContainer>
  );
}

export default memo(AdminPage);
