import { Space } from "antd";
import { SpaceSize } from "antd/es/space";
import classNames from "classnames";
import React from "react";
import { Direction } from "types";

type FieldProps = {
  label?: string;
  labelClassName?: string;
  direction?: Direction;
  size?: SpaceSize;
  children: React.ReactNode;
};

function Field({ label, labelClassName, direction, size, children }: FieldProps) {
  return (
    <div className="__field__">
      <Space direction={direction} size={size}>
        <span className={classNames("ml-1", labelClassName)}>{label}</span>
        {children}
      </Space>
    </div>
  );
}

Field.defaultProps = {
  direction: "vertical",
  size: 5,
};

export default Field;
