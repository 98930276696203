import { Form, FormInstance, Input, InputNumber, Switch } from "antd";
import { renderDurationSection } from "pages/promotions/helper";
import { PromotionCashBackType } from "types/promotion";
import React from "react";

interface PromotionCashBackProps {
  form: FormInstance;
}

type FormValueChanged = Partial<PromotionCashBackType>;

function PromotionCashBack({ form }: PromotionCashBackProps) {
  const onValuesChange = (changedValues: FormValueChanged) => {
    if ("duration" in changedValues) {
      if (changedValues.duration === "day") {
        form.setFieldValue("atDay", 0);
      } else {
        form.resetFields(["atDay"]);
      }
    }
  };

  return (
    <div className="promotion-cashback pt-2">
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
        initialValues={{
          percentAmount: 0,
          duration: "day",
          atDay: null,
          enable: true,
        }}
      >
        <Form.Item
          name="percentAmount"
          label="จำนวนในการคืนยอดเสีย ( % )"
          rules={[{ required: true, message: "ระบุจำนวนในการคืนยอดเสีย" }]}
        >
          <InputNumber step={0.1} min={0} max={100} style={{ width: 100 }} />
        </Form.Item>

        {renderDurationSection()}

        <Form.Item name="description" label="รายละเอียดการคืนยอดเสีย">
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} style={{ width: 420 }} />
        </Form.Item>
        <Form.Item name="enable" label="เปิด/ปิด การคืนยอดเสีย" valuePropName="checked">
          <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
        </Form.Item>
      </Form>
    </div>
  );
}

export default PromotionCashBack;
