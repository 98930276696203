import {
  PromotionAffSubType,
  PromotionAffSubTypeOption,
  PromotionHistoryStatus,
  PromotionHistoryStatusOption,
  PromotionType,
  PromotionTypeOption,
} from "./../../types/promotion";
export const durationOptions = [
  { label: "รายวัน", value: "day" },
  { label: "รายสัปดาห์", value: "week" },
  { label: "รายเดือน", value: "month" },
];

export const durationDayOptions = [
  { label: "วันจันทร์", value: "mon" },
  { label: "วันอังคาร", value: "tue" },
  { label: "วันพุธ", value: "wed" },
  { label: "วันพฤหัสบดี", value: "thu" },
  { label: "วันศุกร์", value: "fri" },
  { label: "วันเสาร์", value: "sat" },
  { label: "วันอาทิตย์", value: "sun" },
];

export const bonusTypeOptions: PromotionTypeOption[] = [
  { label: "แนะนำเพื่อน", value: "AFF" },
  { label: "คืนยอดเสีย", value: "CashBack" },
  { label: "คอมมิชชั่น", value: "Commission" },
];

export const affSubTypeOptions: PromotionAffSubTypeOption[] = [
  { label: "ยอดแพ้", value: "winloss" },
  { label: "ยอดเทิร์น", value: "turnOver" },
];

export const promotionHistoryStatusOptions: PromotionHistoryStatusOption[] = [
  { label: "รอรับ", value: "P" },
  { label: "รับแล้ว", value: "S" },
  { label: "ยกเลิก", value: "C" },
];

export const bonusTypeMapLabel: { [K in PromotionType]: string } = {
  AFF: "แนะนำเพื่อน",
  CashBack: "คืนยอดเสีย",
  Commission: "คอมมิชชั่น",
} as const;

export const affSubTypeMapLabel: { [K in PromotionAffSubType]: string } = {
  winloss: "ยอดแพ้",
  turnOver: "เทิร์น",
} as const;

export const promotionHistoryStatusMapLabel: { [K in PromotionHistoryStatus]: string } = {
  P: "รอรับ",
  S: "รับแล้ว",
  C: "ยกเลิก",
} as const;
