import {
  FetchAccountsResponse,
  FetchTransactionsRequest,
  FetchTransactionsResponse,
  GetKbankTransDetailRequest,
  GetKbankTransDetailResponse,
  InquiryAccountDetailRequest,
  InquiryAccountDetailResponse,
  TransferHistoryRequest,
  TransferHistoryResponse,
  TransferRequest,
} from "types/account";
import { GetBankListResponse } from "types/bank";
import { AxiosAPI } from "./axios-service-creator";

export const AccountManagementService = {
  fetchAccounts: () => AxiosAPI.get<FetchAccountsResponse>("/accounts"),
  fetchTransactions: (params: FetchTransactionsRequest) =>
    AxiosAPI.get<FetchTransactionsResponse>("/accounts/info", { params }),
  getKbankTransDetail: (params: GetKbankTransDetailRequest) =>
    AxiosAPI.get<GetKbankTransDetailResponse>("/accounts/info/kbank/detail", { params }),
  inquiryAccountDetail: (payload: InquiryAccountDetailRequest, signal?: AbortSignal) =>
    AxiosAPI.post<InquiryAccountDetailResponse>("/accounts/transfer/inquiry", payload, { signal }),
  transfer: (payload: TransferRequest) => AxiosAPI.post("/accounts/transfer/confirm", payload),
  fetchTransferHistories: (params: TransferHistoryRequest, signal?: AbortSignal) =>
    AxiosAPI.get<TransferHistoryResponse>("/accounts/transfer/history", { params, signal }),
  getBankList: () => AxiosAPI.get<GetBankListResponse>("/accounts/banks"),
};
