import create from "zustand";
import cloneDeep from "lodash/cloneDeep";
import update from "immutability-helper";
import { IBank } from "types/bank";
import { alertError } from "utils/error-util";
import { AccountManagementService } from "services/account-management-service";
import { IScreen } from "types/admin";
import { AdminService } from "services/admin-service";

type DataState<T> = {
  loading: boolean;
  data: T;
};

type State = {
  bankList: DataState<IBank[]>;
  screenList: DataState<IScreen[]>;
};

type Actions = {
  getBankList: () => void;
  getScreenList: () => void;
  resetState: () => void;
};

const initialState: State = {
  bankList: {
    loading: false,
    data: [],
  },
  screenList: {
    loading: false,
    data: [],
  },
};

type StoreState = State & Actions;

const useStore = create<StoreState>((set, get) => ({
  ...cloneDeep(initialState),
  getBankList: async () => {
    if (get().bankList.data.length === 0) {
      try {
        set((state) => update(state, { bankList: { loading: { $set: true } } }));
        const response = await AccountManagementService.getBankList();
        set((state) => update(state, { bankList: { $merge: { data: response.data.result } } }));
      } catch (error) {
        alertError(error?.message);
      } finally {
        set((state) => update(state, { bankList: { loading: { $set: false } } }));
      }
    }
  },
  getScreenList: async () => {
    if (get().screenList.data.length === 0) {
      try {
        set((state) => update(state, { screenList: { loading: { $set: true } } }));
        const screenList = await AdminService.getScreenList();
        set((state) => update(state, { screenList: { $merge: { data: screenList } } }));
      } catch (error) {
        alertError(error?.message);
      } finally {
        set((state) => update(state, { screenList: { loading: { $set: false } } }));
      }
    }
  },
  resetState: () => set(cloneDeep(initialState)),
}));

export const useBankList = () => useStore((state: StoreState) => state.bankList.data);
export const useBankListLoading = () => useStore((state: StoreState) => state.bankList.loading);

export const useGetBankList = () => useStore((state: StoreState) => state.getBankList);
export const useGetScreenList = () => useStore((state: StoreState) => state.getScreenList);
