import {
  PromotionHistoriesResponse,
  PromotionSetupResponse,
  PromotionSetupType,
  SearchPromotionHistories,
  UpdatePromotionStatusRequest,
} from "types/promotion";
import { AxiosAPI } from "./axios-service-creator";

export const PromotionAPI = {
  getPromotionSetup: () => AxiosAPI.get<PromotionSetupResponse>("/promotion"),
  savePromotionSetup: (payload: PromotionSetupType) => AxiosAPI.post<PromotionSetupResponse>("/promotion", payload),
  fetchPromotionHistories: (payload: SearchPromotionHistories) =>
    AxiosAPI.post<PromotionHistoriesResponse>("/bonus", payload),
  updatePromotionStatus: (payload: UpdatePromotionStatusRequest) => AxiosAPI.post("/bonus/update", payload),
};
