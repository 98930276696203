import { Grid, Select, Space, Spin, Table, TablePaginationConfig, TableProps, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { PromotionAffSubType, PromotionHistory, PromotionHistoryStatus, PromotionType } from "types/promotion";
import { rowIndex } from "utils/pagination-util";
import {
  affSubTypeMapLabel,
  bonusTypeMapLabel,
  promotionHistoryStatusMapLabel,
  promotionHistoryStatusOptions,
} from "../const";
import { formatNumber } from "utils/format-util";
import dayjs from "dayjs";
import styled, { css } from "styled-components";
import { BiEdit, BiSave } from "react-icons/bi";
import { IoMdCloseCircle } from "react-icons/io";

type PromotionListTableProps = TableProps<PromotionHistory> & {
  total: number;
  editHistoryId: string[];
  saveEditHistoryId: string[];
  addEditHistoryId: (id: string) => void;
  onChangeStatus: (id: string, status: PromotionHistoryStatus) => void;
  onEditingStatusChange: (id: string, status: PromotionHistoryStatus) => void;
  onCloseEditStatus: (id: string) => void;
};

function PromotionListTable({
  total,
  pagination,
  editHistoryId,
  saveEditHistoryId,
  addEditHistoryId,
  onChangeStatus,
  onEditingStatusChange,
  onCloseEditStatus,
  ...props
}: PromotionListTableProps) {
  const breakpoint = Grid.useBreakpoint();
  const _pagination = pagination as TablePaginationConfig;

  console.log();

  const columns: ColumnsType<PromotionHistory> = [
    {
      title: "#",
      key: "__no__",
      width: 50,
      align: "center",
      render: (_: unknown, __: unknown, index: number) => rowIndex(_pagination.current, _pagination.pageSize, index),
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "playerName",
    },
    {
      title: "ประเภทโบนัส",
      dataIndex: "bonusType",
      width: !breakpoint.lg ? 120 : undefined,
      align: "center",
      render: (bonusType: PromotionType) => {
        return bonusTypeMapLabel[bonusType];
      },
    },
    {
      title: "ประเภทคำนวณ",
      dataIndex: "subType",
      width: !breakpoint.lg ? 120 : undefined,
      align: "center",
      render: (subType: PromotionAffSubType, record) => {
        if (record.bonusType !== "Commission") return;
        const color = subTypeColor[subType];
        return (
          <Tag color={color} style={{ marginRight: 0 }}>
            {affSubTypeMapLabel[subType]}
          </Tag>
        );
      },
    },
    {
      title: "ยอดเงิน",
      dataIndex: "amount",
      width: !breakpoint.lg ? 140 : undefined,
      align: "right",
      render: (amount: number) => {
        return <DarkcyanLabel>+{formatNumber(amount)}</DarkcyanLabel>;
      },
    },
    {
      title: "วันที่",
      dataIndex: "createDate",
      width: !breakpoint.lg ? 120 : undefined,
      align: "center",
      render: (createDate: Date) => {
        return dayjs(createDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "สถานะ",
      width: 160,
      dataIndex: "status",
      align: "center",
      fixed: "right",
      render: (status: PromotionHistoryStatus, record) => {
        if (editHistoryId.includes(record.id)) {
          return (
            <Spin spinning={saveEditHistoryId.includes(record.id)}>
              <Space size={10}>
                <Tooltip title="ปิด" placement="top">
                  <IoMdCloseCircle size={20} color="#e63f4d" onClick={() => onCloseEditStatus(record.id)} />
                </Tooltip>
                <Select
                  value={record.editingStatus}
                  onChange={(status) => onEditingStatusChange(record.id, status)}
                  options={promotionHistoryStatusOptions}
                  size="small"
                />
                <Tooltip title="บันทึก" placement="top">
                  <DisableUI
                    onClick={() => onChangeStatus(record.id, "S")}
                    $disabled={record.editingStatus === record.status}
                  >
                    <BiSave size={20} color="#57d4a2" />
                  </DisableUI>
                </Tooltip>
              </Space>
            </Spin>
          );
        } else {
          return (
            <div className="status" onClick={() => addEditHistoryId(record.id)}>
              <Space>
                <StatusTag $status={status}>{promotionHistoryStatusMapLabel[status]}</StatusTag>
                {true && (
                  // {status === "P" && (
                  <Tooltip title="แก้ไขสถานะ" placement="top">
                    <BiEdit size={16} />
                  </Tooltip>
                )}
              </Space>
            </div>
          );
        }
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={{ ...pagination, total }}
      scroll={{ x: 900 }}
      size="small"
      sticky
      {...props}
    />
  );
}

export default PromotionListTable;

const subTypeColor: { [K in PromotionAffSubType]: string } = {
  // deposit: "green",
  winloss: "red",
  turnOver: "blue",
};

const DarkcyanLabel = styled.span`
  color: darkcyan;
`;

type StatusTagProps = {
  $status: PromotionHistoryStatus;
};
const StatusTag = styled.div<StatusTagProps>`
  font-size: 0.9em;
  white-space: nowrap;
  padding: 1px 10px;
  margin-block: 4px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => (props.$status === "P" ? "#f7ab22" : props.$status === "S" ? "#34c58f" : "#999999")};
`;

type DisableUIProps = {
  $disabled: boolean;
};
const DisableUI = styled.div<DisableUIProps>`
  ${(props) =>
    props.$disabled
      ? css`
          opacity: 0.3;
          pointer-events: none;
        `
      : ""}
`;
