import { BigNumber } from "bignumber.js";
import { Extends } from "types";

BigNumber.config({
  FORMAT: {
    prefix: "",
    decimalSeparator: ".",
    groupSeparator: ",",
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: " ",
    fractionGroupSize: 0,
    suffix: "",
  },
});

type IFormatNumberOptions = {
  preserveDot?: boolean;
  preserveDecimal?: boolean;
  preserveInteger?: boolean;
  noSep?: boolean;
  trimZeroDecimal?: boolean;
  dontRound?: boolean;
  roundingMode?: BigNumber.RoundingMode;
};

type FormatNumber = string | number | undefined | null;
type Precision = Extends<FormatNumber, number | undefined | null>;
export const formatNumber = (number: FormatNumber, precision?: Precision, options: IFormatNumberOptions = {}) => {
  const {
    preserveDot,
    preserveDecimal,
    preserveInteger,
    noSep,
    trimZeroDecimal,
    dontRound,
    roundingMode = BigNumber.ROUND_HALF_UP,
  } = options;

  number = String(number).replace(/,/g, "");
  if (!number || Number.isNaN(+number)) return number;

  const originStartsWithDot = number[0] === ".";
  const originEndsWithDot = number[number.length - 1] === ".";
  const numberOfDecimal = number.match(/\.(\d+)/)?.[1].length ?? 0;

  // rounding when specify precision
  if (precision !== undefined && precision !== null) {
    if (dontRound) {
      // ถ้า dp เกินใช้วิธีตัดส่วนที่เกินออก, rm จะไม่มีผลถ้า dp พอดี
      if (numberOfDecimal > precision) {
        const cutPosition = number.indexOf(".") + precision + 1;
        number = number.substr(0, cutPosition);
      }
    }
    // ถ้าไม่อยากให้มีการ padding 0 ที่ dp
    if (!preserveDecimal) {
      number = BigNumber(number).toFixed(precision, roundingMode);
    }
  }

  const foundDot = number.indexOf(".") > -1;
  const [integer, decimal = ""] = number.split(".");
  const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  number = `${formattedInteger}${foundDot && decimal ? "." : ""}${decimal}`;

  if (originStartsWithDot && preserveInteger) {
    // case remove 0 :: '0.15' => '.15'
    number = number.replace(/\d+\./, ".");
  }
  if (originEndsWithDot && preserveDot && number.indexOf(".") === -1) {
    number += ".";
  }
  if (noSep) {
    number = number.replaceAll(",", "");
  }
  if (trimZeroDecimal) {
    number = number.replace(/(\.[0-9]*?)0+$/, (_, realDecimal) => (realDecimal !== "." ? realDecimal : ""));
  }

  return number;
};

export const formatAccountNo = (accountNo: string | number | undefined) => {
  if (!accountNo) return "";

  accountNo = "" + accountNo;
  if (accountNo.length < 3) {
    return accountNo;
  } else {
    return accountNo.replace(/(\d{3})?(\d{1})?(\d{5})?(\d{1})?/, (_, ...groups) => {
      return groups.slice(0, 4).join("-").replace(/-{2,}/g, "-");
    });
  }
};
