import { Button, Form, Space, Tabs, message } from "antd";
import Title from "components/Page/Title";
import PageContainer from "pages/container/PageContainer";
import React, { useCallback, useEffect, useState } from "react";
import PromotionAFF from "./tabs/aff/PromotionAFF";
import PromotionCashBack from "./tabs/cash_back/PromotionCashBack";
import PromotionCommission from "./tabs/commision/PromotionCommission";
import { PromotionSetupType } from "../../../types/promotion";
import { PromotionAPI } from "services/promotion-service";
import ButtonIcon from "components/ButtonIcon";
import { AiOutlineReload } from "react-icons/ai";

const TabPane = Tabs.TabPane;

function PromotionSetupPage() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PromotionSetupType>();
  const [activeKey, setActiveKey] = useState("aff");
  const [affForm] = Form.useForm();
  const [cashBackForm] = Form.useForm();
  const [commisionForm] = Form.useForm();

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await PromotionAPI.getPromotionSetup();
    const data = response.data.result;

    setData(data);

    if (data.promotionAff) {
      affForm.setFieldsValue(data.promotionAff);
    }
    if (data.promotionCashBack) {
      cashBackForm.setFieldsValue(data.promotionCashBack);
    }
    if (data.promotionCommision) {
      commisionForm.setFieldsValue(data.promotionCommision);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSave = async () => {
    if (!data) return;

    let affFormValid = true;
    let cashBackFormValid = true;
    let commissionFormValid = true;

    try {
      await affForm.validateFields();
    } catch (_) {
      affFormValid = false;
    }

    try {
      await cashBackForm.validateFields();
    } catch (_) {
      cashBackFormValid = false;
    }

    try {
      await commisionForm.validateFields();
    } catch (_) {
      commissionFormValid = false;
    }

    if (!affFormValid) {
      setActiveKey("aff");
      return;
    }

    if (!cashBackFormValid) {
      setActiveKey("cashBack");
      return;
    }

    if (!commissionFormValid) {
      setActiveKey("commision");
      return;
    }

    //* pass validate all tabs then save it
    setLoading(true);
    const payload = {
      promotionAff: affForm.getFieldsValue(true),
      promotionCashBack: cashBackForm.getFieldsValue(true),
      promotionCommision: commisionForm.getFieldsValue(true),
    };
    await PromotionAPI.savePromotionSetup(payload);
    message.success("บันทึกโปรโมชั่นสำเร็จ ✨");
    setLoading(false);
  };

  return (
    <PageContainer className="promotion-setup-page" loading={loading}>
      <Title className="mb-0" level={4}>
        ตั้งค่าโปรโมชั่น
      </Title>
      <div className="mt-4">
        <div>
          <Space>
            <Button type="primary" onClick={onSave}>
              บันทึกทั้งหมด
            </Button>
            <ButtonIcon icon={<AiOutlineReload />} type="primary" onClick={() => fetchData()}>
              โหลดใหม่
            </ButtonIcon>
          </Space>
        </div>
        <div className="promotion-tabs mt-4 c-container">
          <Tabs activeKey={activeKey} onChange={setActiveKey}>
            <TabPane key="aff" tab={<span className="font-medium">แนะนำเพื่อน (DOWNLINE)</span>} forceRender>
              <PromotionAFF form={affForm} />
            </TabPane>
            <TabPane key="cashBack" tab={<span className="font-medium">คืนยอดเสีย</span>} forceRender>
              <PromotionCashBack form={cashBackForm} />
            </TabPane>
            <TabPane key="commision" tab={<span className="font-medium">คอมมิชชั่น</span>} forceRender>
              <PromotionCommission form={commisionForm} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </PageContainer>
  );
}

export default PromotionSetupPage;
