import { Descriptions, Input, message, Modal, Select } from "antd";
import ButtonIcon from "components/ButtonIcon";
import Skeleton from "components/Misc/Skeleton";
import { useBankList, useGetBankList } from "pages/global-store";
import React, { memo, useState, useEffect, useCallback } from "react";
import { BiTransfer } from "react-icons/bi";
import { FaTrashAlt } from "react-icons/fa";
import { AccountManagementService } from "services/account-management-service";
import { IAccountMinifyInfo } from "types/account";
import { alertError } from "utils/error-util";
import { useOpenWithdrawModal, useSetOpenWithdrawModal } from "./store";

type WithdrawModalProps = {
  accountId: string | undefined;
};

function WithdrawModal({ accountId }: WithdrawModalProps) {
  const [accountNo, setAccountNo] = useState<string>("");
  const [inquiryAccountDetailError, setInquiryAccountDetailErrorError] = useState<string>("");
  const [bankCode, setBankCode] = useState<string | undefined>();
  const [inquiryAccountDetail, setInquiryAccountDetail] = useState(false);
  const [accountNoChecked, setAccountNoChecked] = useState<IAccountMinifyInfo | null>();
  const [amount, setAmount] = useState<string>("");
  const openWithdrawModal = useOpenWithdrawModal();
  const setOpenWithdrawModal = useSetOpenWithdrawModal();
  const bankList = useBankList();
  const getBankList = useGetBankList();

  useEffect(() => {
    getBankList();
  }, []);

  const clearState = useCallback(() => {
    setAccountNo("");
    setBankCode(undefined);
    setInquiryAccountDetail(false);
    setAccountNoChecked(null);
    setAmount("");
    setInquiryAccountDetailErrorError("");
  }, []);

  useEffect(() => {
    if (!openWithdrawModal) {
      clearState();
    }
  }, [openWithdrawModal, clearState]);

  useEffect(() => {
    if (accountNo.length !== 10 || !bankCode) {
      setAccountNoChecked(null);
      setInquiryAccountDetail(false);
    }
  }, [accountNo, bankCode]);

  useEffect(() => {
    if (accountNo.length === 10 && bankCode) {
      setAccountNoChecked(null);
      setInquiryAccountDetail(true);
      setInquiryAccountDetailErrorError("");
      const controller = new AbortController();

      (async () => {
        try {
          const response = await AccountManagementService.inquiryAccountDetail(
            {
              accountId: accountId as string,
              toAccountNo: accountNo,
              toBankCode: bankCode,
            },
            controller.signal
          );
          setAccountNoChecked(response.data.result);
        } catch (error) {
          alertError(error);
          console.log(error.message);
          setInquiryAccountDetailErrorError(error.message);
        } finally {
          setInquiryAccountDetail(false);
        }
      })();

      return () => {
        controller.abort();
      };
    }
    return;
  }, [accountId, accountNo, bankCode]);

  const transfer = useCallback(async () => {
    if (Number(amount) !== 0 && accountNo.length === 10 && bankCode && accountNoChecked) {
      await AccountManagementService.transfer({
        accountId: accountId as string,
        toBankCode: bankCode,
        toAccountNo: accountNoChecked.accountNo,
        toAccountName: accountNoChecked.accountName,
        amount: +amount,
      });
      message.success("โอนเงินเรียบร้อย");
      clearState();
    }
  }, [accountId, amount, accountNo, bankCode, accountNoChecked]);

  return (
    <Modal title={null} open={openWithdrawModal} onCancel={() => setOpenWithdrawModal(false)} footer={null}>
      <div className="mt-6">
        <Descriptions column={1} bordered labelStyle={{ textAlign: "right", width: 180 }}>
          <Descriptions.Item label="เลขบัญชีปลายทาง">
            <Input
              value={accountNo}
              onChange={(evt) => setAccountNo(evt.target.value)}
              onKeyPress={(evt) => {
                if (!/\d+/.test(evt.key)) {
                  evt.preventDefault();
                }
              }}
              style={{ width: "100%" }}
              placeholder="เลขบัญชี 10 หลัก"
              maxLength={10}
              allowClear
            />
          </Descriptions.Item>
          {accountNo.length === 10 && bankCode && (
            <Descriptions.Item label="ชื่อบัญชีปลายทาง">
              <div className="ml-2">
                {inquiryAccountDetailError ? (
                  <span className="text-red-500">* {inquiryAccountDetailError}</span>
                ) : (
                  <Skeleton text={!inquiryAccountDetail && accountNoChecked?.accountName} />
                )}
              </div>
            </Descriptions.Item>
          )}
          <Descriptions.Item label="ธนาคาร">
            <Select
              showSearch
              value={bankCode}
              onChange={setBankCode}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.children as unknown as string).includes(input)}
              style={{ width: "100%" }}
            >
              {bankList.map((bank) => (
                <Select.Option key={bank.bankCode} value={bank.bankCode}>
                  {bank.bankThName} ({bank.bankAbbr})
                </Select.Option>
              ))}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label="จำนวน">
            <Input
              value={amount}
              onChange={(evt) => setAmount(evt.target.value)}
              onKeyPress={(evt) => {
                if (!/\d+/.test(evt.key)) {
                  evt.preventDefault();
                }
              }}
              style={{ width: "100%" }}
              placeholder="จำนวนเงินที่ต้องการโอนออก"
              allowClear
            />
          </Descriptions.Item>
        </Descriptions>
        <div className="my-4 flex justify-center gap-4">
          <ButtonIcon
            className="border-gray-500"
            icon={<FaTrashAlt size={16} className="text-gray-500" />}
            onClick={clearState}
            style={{ width: 150 }}
          >
            ล้างข้อมูล
          </ButtonIcon>
          <ButtonIcon
            icon={<BiTransfer size={18} />}
            type="primary"
            style={{ width: 150 }}
            onClick={transfer}
            disabled={!(Number(amount) ?? 0) || !bankCode || accountNo.length !== 10 || !accountNoChecked?.accountName}
          >
            ยืนยัน
          </ButtonIcon>
        </div>
      </div>
    </Modal>
  );
}

export default memo(WithdrawModal);
