import { message } from "antd";

export const alertError = (error: unknown) => {
  console.error(error);
  if (typeof error === "string") {
    message.error("เกิดข้อผิดพลาด: " + error);
  } else if (error instanceof Error) {
    message.error("เกิดข้อผิดพลาด: " + error.message);
  }
};
