import classNames from "classnames";
import React from "react";

const getWidth = (width: number | string) => {
  return typeof width === "string" ? width : width + "px";
};

const Skeleton = ({ text, width, className }: { text?: unknown; width?: number | string; className?: string }) => (
  <>
    {!text ? (
      <div
        className={classNames("animate-pulse h-3 bg-slate-200 rounded", className)}
        style={width ? { width: getWidth(width) } : undefined}
      ></div>
    ) : (
      text
    )}
  </>
);

export default Skeleton;
