import { Col, DatePicker, Form, FormInstance, Grid, Input, InputNumber, Row, Select } from "antd";
import React from "react";
import { SearchPromotionHistories } from "types/promotion";
import { affSubTypeOptions, bonusTypeOptions, promotionHistoryStatusOptions } from "../const";
import th_TH from "antd/es/date-picker/locale/th_TH";
import FilterContainer from "components/Page/FilterContainer";

interface FilterFormProps {
  form: FormInstance;
  filterVisible: boolean;
  onFilterVisibleChange: (visible: boolean) => void;
  onSearch: () => void;
}

type FormValueChanged = Partial<SearchPromotionHistories>;

function FilterForm({ form, filterVisible, onFilterVisibleChange, onSearch }: FilterFormProps) {
  const { md, lg, xl, sm } = Grid.useBreakpoint();
  const spanValue = xl ? 6 : lg ? 8 : md ? 8 : sm ? 12 : 24;

  const onValuesChange = (changedValues: FormValueChanged) => {
    if ("type" in changedValues && changedValues.type !== "AFF") {
      form.setFieldValue("subType", null);
    }
  };

  return (
    <FilterContainer
      title="ค้นหาข้อมูล"
      className="promotion-history-filter"
      filterVisible={filterVisible}
      onFilterVisibleChange={onFilterVisibleChange}
      onSearch={() => onSearch()}
    >
      <Form form={form} layout={!md ? "vertical" : "horizontal"} onFinish={onSearch} onValuesChange={onValuesChange}>
        <Row gutter={16}>
          <Col span={spanValue}>
            <Form.Item name="playerName" label="ชื่อลูกค้า">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={spanValue}>
            <Form.Item name="type" label="ประเภทโบนัส">
              <Select options={bonusTypeOptions} allowClear />
            </Form.Item>
          </Col>
          <Col span={spanValue}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const bonusType = getFieldValue("type");
                return (
                  <Form.Item name="subType" label="ประเภทการคำนวณ">
                    <Select
                      options={affSubTypeOptions}
                      allowClear
                      disabled={bonusType !== "AFF"}
                      style={{ minWidth: 110 }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={spanValue}>
            <Form.Item name="amount" label="ยอดเงินมากกว่า">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={spanValue}>
            <Form.Item name="startDate" label="วันที่">
              <DatePicker locale={th_TH} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col span={spanValue}>
            <Form.Item name="endDate" label="ถึง">
              <DatePicker locale={th_TH} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col span={spanValue}>
            <Form.Item name="status" label="สถานะ">
              <Select options={promotionHistoryStatusOptions} allowClear style={{ width: 100 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </FilterContainer>
  );
}

export default FilterForm;
