import "./App.scss";
import "./App_antd.less";
import React from "react";
import { ConnectedRouter } from "connected-react-router";
import store, { history, persistor } from "./redux/store";
import Main from "./pages/Main";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <div className="AUTO-ADMIN font-kanit">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <Main />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
