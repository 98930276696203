import { AxiosAPI } from './axios-service-creator';

type SendSmsPayload = {
  message: string,
  toList: string[];
}

export const SmsAPI = {
  getSmsActivities: () => AxiosAPI.get('/sms'),
  sendSms: (payload: SendSmsPayload) => AxiosAPI.post('/sms', payload),
  uploadSmsExcel: (payload: FormData) => AxiosAPI.post('/sms/upload', payload),
}