import { Tag } from "antd";
import React from "react";
import { IProcessStatus } from "types";

type TagStatusProps = {
  status: IProcessStatus;
};

function TagStatus({ status }: TagStatusProps) {
  switch (status) {
    case "S":
      return <Tag color="#87d068">สำเร็จ</Tag>;
    case "P":
      return <Tag color="#fb9f36">รอดำเนินการ</Tag>;
    case "F":
      return <Tag color="#3681fa">หมดเวลา</Tag>;
    case "R":
      return <Tag color="#50535c">ยกเลิกรายการ</Tag>;
    default:
      return null;
  }
}

export default TagStatus;
