import { useCallback, useEffect, useRef } from "react";

function useUnmount() {
  const isUnmount = useRef(false);

  useEffect(() => {
    isUnmount.current = false
    return () => {
      isUnmount.current = true
    };
  });

  const canUpdateState = useCallback(() => {
    return !isUnmount.current;
  }, []);

  return canUpdateState;
}

export default useUnmount;
