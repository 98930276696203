import { RootStateOrAny } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import rootSaga from "./sagas";
import createRootReducer from "./reducers";
import { PayloadAction } from "@reduxjs/toolkit";
import { initialStore } from "./reducers/auth";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import { AxiosAPI } from "services/axios-service-creator";

export const history = createBrowserHistory();

// Middleware
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

// Root Reducer
const persistConfig = {
  key: "root",
  whitelist: ["Authentication"],
  storage,
};
const appReducer = createRootReducer(history);
const rootReducer = (state: RootStateOrAny, action: PayloadAction) => {
  if (action.type === initialStore.type) {
    // state = undefined;
    const { router } = state
    state = { router }
  }
  return appReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: false,
    })
      .concat(sagaMiddleware)
      .concat(routeMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store, {}, () => {
  const state = store.getState();
  if (state.Authentication.accessToken) {
    AxiosAPI.defaults.headers.common["Authorization"] = state.Authentication.accessToken;
  }
});
export default store;
