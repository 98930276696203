import { Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
import React from "react";
import { formatNumber } from "utils/format-util";

type NumberProps = string | number | undefined | null;
type LabelUnitProps = TextProps & {
  label: string;
  number?: NumberProps;
  suffix?: string;
  format?: boolean;
  precision?: number;
  renderNumber?: (number: NumberProps) => React.ReactNode | NumberProps;
};

function LabelUnit({ label, number, format, precision, suffix, renderNumber, ...props }: LabelUnitProps) {
  const _renderNumber = () => {
    const _number = format ? formatNumber(number, precision) : number;
    return renderNumber ? renderNumber(_number) : _number;
  };
  return (
    <Typography.Text {...props}>
      {label} <span className="mx-1">{_renderNumber()}</span> {suffix}
    </Typography.Text>
  );
}

LabelUnit.defaultProps = {
  number: 0,
  format: false,
};

export default LabelUnit;
