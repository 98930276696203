import { Spin } from "antd";
import classNames from "classnames";
import React from "react";
import styled from "styled-components";

interface PageContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  loading?: boolean;
}

function PageContainer({ className, loading, children, ...props }: PageContainerProps) {
  return (
    <PageContainerStyled className={classNames("p-4", className)} {...props}>
      <Spin spinning={!!loading}>{children}</Spin>
    </PageContainerStyled>
  );
}

export default PageContainer;

const PageContainerStyled = styled.div``;
