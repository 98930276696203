import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";

type State = {
  loading: boolean;
  accessToken?: string;
  screenRoles: string[];
  error: string | null;
};

type SignInSuccessActionType = {
  type: string;
  payload: Pick<State, "accessToken" | "screenRoles">;
};

const initialState: State = {
  loading: false,
  screenRoles: [],
  error: null,
};

const slice = createSlice({
  initialState: cloneDeep(initialState),
  name: "Authentication",
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    signIn: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    signInSuccess: (state, action: SignInSuccessActionType) => {
      state.loading = false;
      state.accessToken = action.payload.accessToken;
      state.screenRoles = action.payload.screenRoles;
    },
    signInFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearToken: (state) => {
      state.accessToken = undefined;
    },
    initialStore: () => cloneDeep(initialState),
  },
});

export const { signIn, signInSuccess, signInFailure, clearToken, initialStore } = slice.actions;

export default slice;
