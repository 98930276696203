import { Button, Space } from "antd";
import React from "react";
import Title from "./Title";
import { FilterFilled } from "@ant-design/icons";
import classNames from "classnames";

type FilterContainer = {
  title?: React.ReactNode;
  filterLabel?: string;
  filterVisible?: boolean;
  onFilterVisibleChange?: (visible: boolean) => void;
  searchLabel?: string;
  onSearch?: () => void;
  children?: React.ReactNode;
  className?: string;
};

function FilterContainer({
  title,
  searchLabel,
  onSearch,
  filterVisible,
  filterLabel,
  onFilterVisibleChange,
  children,
  className,
}: FilterContainer) {
  return (
    <div className={classNames("filter-container c-container", className)}>
      <div className="filter-title flex justify-between">
        <Space align="baseline" size={20}>
          <Title className="page-title mb-0" level={5}>
            {title}
          </Title>
          {onFilterVisibleChange && (
            <Button type="primary" icon={<FilterFilled />} onClick={() => onFilterVisibleChange(!filterVisible)}>
              {filterLabel}
            </Button>
          )}
        </Space>
        <Button type="primary" onClick={onSearch}>
          {searchLabel}
        </Button>
      </div>
      {filterVisible && <div className="filter-content flex gap-6 mt-3">{children}</div>}
    </div>
  );
}

FilterContainer.defaultProps = {
  title: "ค้นหาข้อมูล",
  searchLabel: "ค้นหา",
};

export default FilterContainer;
