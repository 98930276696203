import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { menus } from "const/menus";
import { call, put, takeLatest } from "redux-saga/effects";
import { signIn, signInFailure, signInSuccess } from "redux/reducers/auth";
import { AuthenticationAPI, SignInPayload } from "services/auth-service";
import { AxiosAPI } from "services/axios-service-creator";
import { AuthenticateResponse } from "types";
import { findLandingPage } from "utils/menu-util";

function* signInSaga(action: PayloadAction<SignInPayload>) {
  try {
    const response: AuthenticateResponse = yield call(AuthenticationAPI.signIn, action.payload);
    const { accessToken, screens } = response.data.result;
    const token = `Bearer ${accessToken}`;
    AxiosAPI.defaults.headers.common["Authorization"] = token;

    yield put(
      signInSuccess({
        accessToken: token,
        screenRoles: screens?.map((s) => s.screenCode) ?? [],
      })
    );

    // default page
    const landingMenu = findLandingPage(menus, screens?.[0]?.screenCode);
    if (landingMenu?.path) {
      yield put(push(landingMenu.path));
    }
  } catch (error) {
    yield put(signInFailure(error.message));
  }
}

const saga = [takeLatest(signIn.type, signInSaga)];

export default saga;
