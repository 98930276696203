import { call, put, takeLatest } from "redux-saga/effects";
import { getSmsActivities, getSmsActivitiesFailure, getSmsActivitiesSuccess } from "redux/reducers/sms";
import { SmsAPI } from "services/sms-service";
import { SmsActivityResponse } from "types";

function* getSmsActivitiesSaga() {
  try {
    const response: SmsActivityResponse = yield call(SmsAPI.getSmsActivities);
    const { credit, details: smsActivities } = response.data.result;
    yield put(getSmsActivitiesSuccess({ credit, smsActivities }));
  } catch (error) {
    console.error(error);
    yield put(getSmsActivitiesFailure(error.message));
  }
}

const saga = [takeLatest(getSmsActivities.type, getSmsActivitiesSaga)];

export default saga;
