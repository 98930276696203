import { UnorderedListOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import TagAccountStatus from "components/Tag/TagAccountStatus";
import { AccountTypeDesc } from "const";
import React, { memo } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { IAccount, IAccountStatus, IAccountType } from "types/account";

type AccountTableProps = TableProps<IAccount> & {
  onViewStatement: (record: IAccount) => void;
};

function AccountTable({ onViewStatement, ...props }: AccountTableProps) {
  const columns: ColumnsType<IAccount> = [
    {
      title: "#",
      key: "__no__",
      width: 50,
      align: "center",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "ชื่อบัญชี",
      dataIndex: "accountName",
    },
    {
      title: "เลขบัญชี",
      dataIndex: "accountNo",
    },
    {
      title: "ธนาคาร",
      dataIndex: "bank",
      align: "center",
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      align: "center",
      render: (type: IAccountType) => AccountTypeDesc[type],
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (status: IAccountStatus) => <TagAccountStatus className="w-[80px]" status={status} />,
    },
    {
      title: "online service",
      dataIndex: "serviceOnline",
      align: "center",
      width: 150,
      render: (serviceOnline: boolean) => (
        <div title={serviceOnline ? "เชื่อมต่อได้ปกติ" : "ไม่สามารถเชื่อมต่อได้"} className="flex justify-center">
          {serviceOnline ? (
            <FaCheckCircle size={20} color="#87d068" />
          ) : (
            <RiCloseCircleFill size={20} color="#50535c" />
          )}
        </div>
      ),
    },
    {
      key: "__action__",
      align: "center",
      width: 200,
      render: (record: IAccount) => {
        return (
          <Space>
            <Button type="primary" icon={<UnorderedListOutlined />} onClick={() => onViewStatement(record)} size="small">
              รายการฝากถอน
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="accountId"
      className="c-table header-center"
      columns={columns}
      scroll={{
        x: 1246,
      }}
      bordered
      size="small"
      {...props}
    />
  );
}

export default memo(AccountTable);
