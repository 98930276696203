import {
  AddIAdmin,
  EditIAdmin,
  FetchAdminDetailRequest,
  FetchAdminDetailResponse,
  FetchAdminResponse,
  FetchScreenListResponse,
} from "types/admin";
import { IResponse } from "types/default-response";
import { AxiosAPI } from "./axios-service-creator";

export const AdminAPI = {
  fetchAdminList: (signal?: AbortSignal) => AxiosAPI.get<FetchAdminResponse>("/admin", { signal }),
  getAdminDetail: (params: FetchAdminDetailRequest, signal?: AbortSignal) =>
    AxiosAPI.get<FetchAdminDetailResponse>("/admin/detail", { params, signal }),
  getScreenList: () => AxiosAPI.get<FetchScreenListResponse>("/admin/screens"),
  addAdmin: (payload: AddIAdmin) => AxiosAPI.post<IResponse>("/admin/add", payload),
  editAdmin: (payload: EditIAdmin) => AxiosAPI.post<IResponse>("/admin/save", payload),
};

export const AdminService = {
  fetchAdminList: (signal?: AbortSignal) =>
    AdminAPI.fetchAdminList(signal).then((response) => response.data.result.admins),
  getAdminDetail: (params: FetchAdminDetailRequest, signal?: AbortSignal) =>
    AdminAPI.getAdminDetail(params, signal).then((response) => response.data.result),
  getScreenList: () => AdminAPI.getScreenList().then((response) => response.data.result),
  addAdmin: (payload: AddIAdmin) => AdminAPI.addAdmin(payload),
  editAdmin: (payload: EditIAdmin) => AdminAPI.editAdmin(payload),
};
