import { Button, Form, Input, message, Modal, Select, Space, Spin, Switch } from "antd";
import { AdminStatus } from "const";
import { IMenu, menus } from "const/menus";
import React, { memo, useEffect } from "react";
import { AdminService } from "services/admin-service";
import { AddIAdmin, EditIAdmin } from "types/admin";
import { alertError } from "utils/error-util";
import {
  useCloseAdminModal,
  useEditingAdmin,
  useEditingMode,
  useLoadingAdminDetail,
  useSetLoadingAdminDetail,
  useSetVisibleModal,
  useVisibleModal,
} from "./store";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

type AddEditAdminModalProps = {
  fetchAdmins: () => void;
};

function AddEditAdminModal({ fetchAdmins }: AddEditAdminModalProps) {
  const [form] = Form.useForm();
  const visible = useVisibleModal();
  const onVisibleChange = useSetVisibleModal();
  const editingMode = useEditingMode();
  const editingAdmin = useEditingAdmin();
  const closeAdminModal = useCloseAdminModal();
  const loadingAdminDetail = useLoadingAdminDetail();
  const setLoadingAdminDetail = useSetLoadingAdminDetail();

  useEffect(() => {
    const controller = new AbortController();

    const getAdminDetail = async (username: string) => {
      let adminDetail = null;
      try {
        setLoadingAdminDetail(true);
        adminDetail = await AdminService.getAdminDetail({ username }, controller.signal);
      } catch (error) {
        alertError(error);
        closeAdminModal();
      } finally {
        setLoadingAdminDetail(false);
      }

      return adminDetail;
    };

    (async () => {
      if (visible) {
        if (editingMode === "edit" && editingAdmin) {
          const adminDetail = await getAdminDetail(editingAdmin.username);
          if (adminDetail) {
            form.resetFields();
            form.setFieldsValue({
              ...adminDetail,
              status: adminDetail.status === AdminStatus.Active,
              screenList: adminDetail?.screenList?.map((s) => s.screenCode) ?? [],
            });
          }
        } else {
          form.setFieldsValue({
            status: true,
            screenList: [],
          });
        }
      } else {
        form.resetFields();
      }
    })();

    return () => controller.abort();
  }, [form, visible, editingAdmin?.username]);

  const renderScreenList = () => {
    return menus.flatMap((menuGroup: IMenu) => {
      if (menuGroup.menus) {
        return (
          <Select.OptGroup key={menuGroup.code} label={menuGroup.label}>
            {menuGroup.menus.map((menu) => (
              <Select.Option key={menu.code} value={menu.code}>
                <Space>
                  {menu.icon}
                  {menu.label}
                </Space>
              </Select.Option>
            ))}
          </Select.OptGroup>
        );
      } else {
        return (
          <Select.Option key={menuGroup.code} value={menuGroup.code}>
            <Space>
              {menuGroup.icon}
              {menuGroup.label}
            </Space>
          </Select.Option>
        );
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = async (values: any) => {
    try {
      // transform some field for add edit api
      if (values.screenList) {
        values.screenList = values.screenList?.map((screenCode: string) => ({ screenCode, view: true, edit: true }));
      }
      values.status = values.status ? AdminStatus.Active : AdminStatus.Inactive;
      delete values.confirmPassword;

      if (editingMode === "add") {
        const addValues = values as AddIAdmin;
        await AdminService.addAdmin(addValues);
        message.success("เพิ่มผู้ดูแลสำเร็จ ✨");
      } else {
        const editValues = values as EditIAdmin;
        await AdminService.editAdmin(editValues);
        message.success("แก้ไขผู้ดูแลสำเร็จ ✨");
      }

      closeAdminModal();
      fetchAdmins();
    } catch (error) {
      alertError(error);
    }
  };

  return (
    <Modal
      title="เพิ่ม/แก้ไขผู้ดูแล"
      open={visible}
      onCancel={() => onVisibleChange(false)}
      footer={null}
      width={500}
      destroyOnClose
    >
      <Spin spinning={loadingAdminDetail}>
        <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish}>
          {/* Prevent FireFox AutoComplete */}
          <input type="password" style={{ display: "none" }} />

          <Form.Item label="Username :" name="username" rules={[{ required: true, message: "กรุณาระบุ username!" }]}>
            <Input placeholder="username" disabled={editingMode === "edit"} autoComplete="off" allowClear />
          </Form.Item>
          <Form.Item
            label="Password :"
            name="password"
            rules={[
              { required: editingMode === "add", message: "กรุณาระบุ password!" },
              { min: 6, message: "password ต้องมากกว่า 6 ตัวขึ้นไป" },
              { max: 255, message: "password ต้องไม่เกิน 255 ตัวอักษร" },
            ]}
          >
            <Input.Password
              placeholder="password"
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              autoComplete="off"
              allowClear
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.password !== next.password}>
            {({ getFieldValue }) => {
              const requiredConfirmPassword = !!getFieldValue("password");
              return (
                <Form.Item
                  label="Confirm Password :"
                  name="confirmPassword"
                  rules={[
                    { required: editingMode === "add" || requiredConfirmPassword, message: "กรุณา confirm password!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("กรุณาระบุ password ให้เหมือนที่ระบุด้านบน"));
                      },
                    }),
                  ]}
                  dependencies={["password"]}
                >
                  <Input.Password
                    placeholder="confirm password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item label="ชื่อ :" name="firstName" rules={[{ required: true, message: "กรุณาระบุ ชื่อ!" }]}>
            <Input placeholder="ชื่อ" allowClear />
          </Form.Item>
          <Form.Item label="นามสกุล :" name="lastName" rules={[{ required: true, message: "กรุณาระบุ นามสกุล!" }]}>
            <Input placeholder="นามสกุล" allowClear />
          </Form.Item>
          <Form.Item label="สถานะ :" name="status" valuePropName="checked">
            <Switch checkedChildren="เปิดใช้งาน" unCheckedChildren="ระงับการใช้งาน" />
          </Form.Item>
          <Form.Item label="สิทธิ์การใช้งานหน้าจอ :" name="screenList">
            <Select mode="multiple">{renderScreenList()}</Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              return (
                <div className="flex justify-end gap-4 pt-4">
                  <Button className="w-[100px]" type="primary" onClick={closeAdminModal} ghost>
                    ยกเลิก
                  </Button>
                  <Button className="w-[100px]" type="primary" htmlType="submit">
                    บันทึก
                  </Button>
                </div>
              );
            }}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default memo(AddEditAdminModal);
