import { defaultPagination } from "../../const/index";
import create from "zustand";
import update from "immutability-helper";
import { Pagination, IProcessStatus } from "types";
import { TablePaginationConfig } from "antd/es/table";
import { WithdrawReport, FilterFields } from "types/withdraw-report";

type WithdrawReportId = WithdrawReport["id"];

type StoreState = {
  loading: boolean;
  filterVisible: boolean;
  filters: FilterFields;
  statements: WithdrawReport[];
  error?: string;
  pagination: Pagination;
  total: number;
  forceUpdate: number;
  setLoading: (loading: boolean) => void;
  setFilterVisible: (filterVisible: boolean) => void;
  setFilters: (filters: FilterFields) => void;
  setStatements: (statements: WithdrawReport[]) => void;
  setError: (error: string) => void;
  setPagination: (pagination: Partial<TablePaginationConfig>) => void;
  setTotal: (total: number) => void;
  setForceUpdate: () => void;
  updateWithdrawStatus: (id: WithdrawReportId, status: IProcessStatus) => void;
};

const useStore = create<StoreState>((set) => ({
  loading: false,
  filterVisible: true,
  statements: [],
  filters: {},
  pagination: { ...defaultPagination },
  total: 0,
  forceUpdate: 0,
  setLoading: (loading: boolean) => set({ loading }),
  setStatements: (statements: WithdrawReport[]) => set({ statements }),
  setError: (error: string) => set({ error }),
  setFilters: (filters: FilterFields) =>
    set((state) => update(state, { filters: { $merge: filters }, pagination: { current: { $set: 1 } } })),
  setFilterVisible: (filterVisible: boolean) => set({ filterVisible }),
  setPagination: (pagination: Partial<TablePaginationConfig>) =>
    set((state) => update(state, { pagination: { $merge: pagination } })),
  setTotal: (total: number) => set({ total }),
  setForceUpdate: () => set((state) => ({ forceUpdate: state.forceUpdate + 1 })),
  updateWithdrawStatus: (id: WithdrawReportId, status: IProcessStatus) =>
    set((state) => {
      const index = state.statements.findIndex((data) => data.id === id);
      if (index > -1) {
        return update(state, { statements: { [index]: { status: { $set: status } } } });
      }
      return state;
    }),
}));

export const useLoading = () => useStore((state: StoreState) => state.loading);
export const useFilterVisible = () => useStore((state: StoreState) => state.filterVisible);
export const useFilters = () => useStore((state: StoreState) => state.filters);
export const useStatements = () => useStore((state: StoreState) => state.statements);
export const useError = () => useStore((state: StoreState) => state.error);
export const usePagination = () => useStore((state: StoreState) => state.pagination);
export const useTotal = () => useStore((state: StoreState) => state.total);
export const useForceUpdate = () => useStore((state: StoreState) => state.forceUpdate);

export const useSetLoading = () => useStore((state: StoreState) => state.setLoading);
export const useSetFilterVisible = () => useStore((state: StoreState) => state.setFilterVisible);
export const useSetFilters = () => useStore((state: StoreState) => state.setFilters);
export const useSetStatements = () => useStore((state: StoreState) => state.setStatements);
export const useSetError = () => useStore((state: StoreState) => state.setError);
export const useSetPagination = () => useStore((state: StoreState) => state.setPagination);
export const useSetTotal = () => useStore((state: StoreState) => state.setTotal);
export const useSetForceUpdate = () => useStore((state: StoreState) => state.setForceUpdate);
export const useUpdateWithdrawStatus = () => useStore((state: StoreState) => state.updateWithdrawStatus);
