import { Form, FormInstance, InputNumber, Switch } from "antd";
import classNames from "classnames";
import { renderDurationSection } from "pages/promotions/helper";
import { PromotionCommisionType } from "types/promotion";
import React from "react";
import styled from "styled-components";

interface PromotionCashBackProps {
  form: FormInstance;
}

type FormValueChanged = Partial<PromotionCommisionType>;

function PromotionCommission({ form }: PromotionCashBackProps) {
  const onValuesChange = (changedValues: FormValueChanged) => {
    if ("duration" in changedValues) {
      if (changedValues.duration === "day") {
        form.setFieldValue("atDay", 0);
      } else {
        form.resetFields(["atDay"]);
      }
    }
  };

  const renderCommissionTable = () => {
    const elements = [];
    const gameTypeColumn = ["percentCasino", "percentSlot", "percentSport"];
    for (let lv = 0; lv < 10; lv++) {
      for (let col = 0; col < 4; col++) {
        if (col === 0) {
          elements.push(renderHeader(`VIP ${lv + 1}`, "pt-1"));
        } else {
          const gameType = gameTypeColumn[col - 1];
          const nameKey = `${gameType}[${lv}]`;
          const name = [gameType, lv];
          elements.push(
            <Form.Item key={nameKey} name={name} rules={[{ required: true, message: "ระบุ %" }]}>
              <InputNumber step={0.01} min={0} max={1} style={{ width: 100 }} />
            </Form.Item>
          );
        }
      }
    }

    return elements;
  };

  const renderHeader = (text: string, clz?: string) => (
    <div key={text} className={classNames("grid-item header text-md font-medium", clz)}>
      {text}
    </div>
  );

  return (
    <div className="promotion-commission">
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
        initialValues={{
          duration: "day",
          atDay: null,
          enable: true,
          percentCasino: Array(10).fill(0),
          percentSlot: Array(10).fill(0),
          percentSport: Array(10).fill(0),
        }}
      >
        <GridContainer className="grid-container mb-4">
          {["VIP", "Casino", "Slot", "Sport"].map((header) => renderHeader(header, "text-lg font-semibold pt-2"))}
          {renderCommissionTable()}
        </GridContainer>

        {renderDurationSection()}

        <Form.Item name="enable" label="เปิด/ปิด การคืนยอดเสีย" valuePropName="checked">
          <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
        </Form.Item>
      </Form>
    </div>
  );
}

export default PromotionCommission;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(11, 1fr);
  column-gap: 8px;
  width: 400px;

  .grid-item {
    text-align: center;

    &.header {
      text-align: center;
    }
  }
`;
