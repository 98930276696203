import { Form, TablePaginationConfig, message } from "antd";
import PageContainer from "pages/container/PageContainer";
import React, { useEffect, useCallback } from "react";
import FilterForm from "./FilterForm";
import { PromotionAPI } from "services/promotion-service";
import {
  useEditHistoryId,
  useHistories,
  useLoading,
  usePagination,
  useAddEditHistoryId,
  useSetHistories,
  useSetLoading,
  useSetPagination,
  useSetTotal,
  useTotal,
  useRemoveEditHistoryId,
  useClearEditHistoryId,
  useAddSavingEditHistoryId,
  useRemoveSavingEditHistoryId,
  useClearSavingEditHistoryId,
  useUpdatePromotionStatus,
  useSavingEditHistoryId,
  useUpdateEdittingPromotionStatus,
  useFilterVisible,
  useSetFilterVisible,
} from "./store";
import PromotionListTable from "./PromotionListTable";
import { PromotionHistoryStatus, SearchPromotionHistories } from "types/promotion";
import dayjs from "dayjs";

function PromotionHistoryPage() {
  const [form] = Form.useForm();
  const loading = useLoading();
  const filterVisible = useFilterVisible();
  const histories = useHistories();
  const pagination = usePagination();
  const total = useTotal();
  const editHistoryId = useEditHistoryId();
  const saveEditHistoryId = useSavingEditHistoryId();
  const setLoading = useSetLoading();
  const setFilterVisibleChange = useSetFilterVisible();
  const setHistories = useSetHistories();
  const setPagination = useSetPagination();
  const setTotal = useSetTotal();
  const addEditHistoryId = useAddEditHistoryId();
  const removeEditHistoryId = useRemoveEditHistoryId();
  const clearEditHistoryId = useClearEditHistoryId();
  const addSavingEditHistoryId = useAddSavingEditHistoryId();
  const removeSavingEditHistoryId = useRemoveSavingEditHistoryId();
  const clearSavingEditHistoryId = useClearSavingEditHistoryId();
  const updatePromotionStatus = useUpdatePromotionStatus();
  const updateEdittingPromotionStatus = useUpdateEdittingPromotionStatus();

  const onSearch = useCallback(async () => {
    setLoading(true);
    setPagination({ current: 1 });
    try {
      const payload: SearchPromotionHistories = {
        ...form.getFieldsValue(true),
        page: pagination.current ?? 1,
        perPage: pagination.pageSize ?? 10,
      };
      clearEditHistoryId();
      clearSavingEditHistoryId();

      //* adjust time of day
      if (payload.startDate) {
        payload.startDate = dayjs(payload.startDate).startOf("day").toDate();
      }
      if (payload.endDate) {
        payload.endDate = dayjs(payload.endDate).endOf("day").toDate();
      }

      const response = await PromotionAPI.fetchPromotionHistories(payload);
      const { data, total } = response.data.result;
      setHistories(data ?? []);
      setTotal(total);
    } catch (error) {
      setHistories([]);
      setTotal(0);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [form, pagination]);

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  const onTableChange = useCallback((pagination: TablePaginationConfig) => {
    setPagination(pagination);
  }, []);

  const onChangeStatus = useCallback(async (id: string, status: PromotionHistoryStatus) => {
    try {
      addSavingEditHistoryId(id);
      await PromotionAPI.updatePromotionStatus({ id, status });
      updatePromotionStatus(id, status);
    } catch (error) {
      message.error(error.message);
    } finally {
      removeEditHistoryId(id);
      removeSavingEditHistoryId(id);
    }
  }, []);

  const onEditingStatusChange = useCallback((id: string, status: PromotionHistoryStatus) => {
    updateEdittingPromotionStatus(id, status);
  }, []);

  const onCloseEditStatus = useCallback((id: string) => {
    removeEditHistoryId(id);
  }, []);

  return (
    <PageContainer className="promotion-history-page" loading={loading}>
      <FilterForm
        form={form}
        filterVisible={filterVisible}
        onFilterVisibleChange={setFilterVisibleChange}
        onSearch={onSearch}
      />

      <div className="c-container mt-4">
        <PromotionListTable
          loading={loading}
          dataSource={histories}
          pagination={pagination}
          total={total}
          onChange={onTableChange}
          editHistoryId={editHistoryId}
          saveEditHistoryId={saveEditHistoryId}
          addEditHistoryId={addEditHistoryId}
          onChangeStatus={onChangeStatus}
          onEditingStatusChange={onEditingStatusChange}
          onCloseEditStatus={onCloseEditStatus}
        />
      </div>
    </PageContainer>
  );
}

export default PromotionHistoryPage;
