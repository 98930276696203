import { Button, Divider, Form, Input, Popover, Upload, message, Spin, Space } from "antd";
import { useUnmount } from "hooks";
import React, { memo, useCallback, useState } from "react";
import { MdAddCircle, MdUploadFile } from "react-icons/md";
import { SmsAPI } from "services/sms-service";
import { FaSms } from "react-icons/fa";
import { CgCloseR } from "react-icons/cg";
import ButtonIcon from "components/ButtonIcon";
import type { RcFile } from "antd/es/upload/interface";
import PhoneMethod from "./misc/PhoneMethod";
import { ListMethod } from "./type";
import update from "immutability-helper";

function UploadSmsExcel({ refresh }: { refresh: () => void }) {
  const [uploading, setUploading] = useState(false);
  const [listMethod, setListMethod] = useState<ListMethod>("excel");
  const [sending, setSending] = useState(false);
  const [phoneListExcel, setPhoneListExcel] = useState<string[]>([]);
  const [phoneListCustom, setPhoneListCustom] = useState<string[]>([""]);
  const [form] = Form.useForm();
  const canUpdateState = useUnmount();

  const onUploadFile = (file: RcFile) => {
    (async () => {
      try {
        setUploading(true);
        const formData = new FormData();
        formData.append("file", file);
        const response = await SmsAPI.uploadSmsExcel(formData);
        setPhoneListExcel(response.data.result);
      } catch (error) {
        console.error(error);
        message.error("เกิดข้อผิดพลาด:" + error.message);
      } finally {
        if (canUpdateState()) {
          setUploading(false);
        }
      }
    })();
    return false;
  };

  const sendSms = useCallback(
    async (values: { message: string }) => {
      try {
        setSending(true);
        const phoneList = listMethod === "excel" ? phoneListExcel : phoneListCustom;
        const payload = {
          toList: phoneList.filter((p) => p),
          message: values.message,
        };
        await SmsAPI.sendSms(payload);
        message.success("ส่งข้อความ SMS สำเร็จ");
        refresh();
      } catch (error) {
        console.error(error);
        message.error("เกิดข้อผิดพลาด:" + error.message);
      } finally {
        if (canUpdateState()) {
          setSending(false);
        }
      }
    },
    [canUpdateState, phoneListExcel, phoneListCustom, refresh]
  );

  const renderPhoneMethodList = () => (
    <ul className="flex justify-end gap-2 text-zinc-400">
      <PhoneMethod label="Excel" method="excel" active={listMethod === "excel"} onSelect={setListMethod} />
      <PhoneMethod label="ระบุเอง" method="custom" active={listMethod === "custom"} onSelect={setListMethod} />
    </ul>
  );

  const renderPhoneMethodInput = (method: string) =>
    method === "excel" ? (
      <>
        <Upload beforeUpload={onUploadFile} showUploadList={false} accept=".xls, .xlsx">
          <Spin spinning={uploading}>
            <ButtonIcon type="primary" icon={<MdUploadFile size={20} />} shape="round">
              เลือกไฟล์ Excel
            </ButtonIcon>
          </Spin>
        </Upload>
        {!!phoneListExcel.length && (
          <div className="mt-2 text-lg text-slate-500">
            จำนวน <b className="text-black">{phoneListExcel.length}</b> เบอร์
          </div>
        )}
      </>
    ) : (
      <div className="px-2 max-h-[230px] overflow-auto">
        <Space direction="vertical">
          {phoneListCustom.map((phone, index) => (
            <Space key={index} className="relative">
              <Input
                className="text-center"
                value={phone}
                onChange={(evt) => {
                  setPhoneListCustom((phoneList) => {
                    return update(phoneList, {
                      [index]: {
                        $set: evt.target.value.replace(/[^\d]/g, ""),
                      },
                    });
                  });
                }}
              />
              <span
                className="text-red-500"
                onClick={() => setPhoneListCustom((p) => update(p, { $splice: [[index, 1]] }))}
              >
                <CgCloseR size={16} />
              </span>
            </Space>
          ))}
          <ButtonIcon
            className="mt-1"
            type="primary"
            icon={<MdAddCircle size={16} />}
            ghost
            block
            onClick={() => setPhoneListCustom((p) => [...p, ""])}
          >
            เพิ่มหมายเลข
          </ButtonIcon>
        </Space>
      </div>
    );

  return (
    <Popover
      title={<span className="text-lg">อัพโหลดเบอร์มือถือปลายทาง</span>}
      trigger="click"
      placement="bottomRight"
      destroyTooltipOnHide
      content={
        <div className="popup-sms-upload">
          <div className="text-center">
            <div className="mb-2">{renderPhoneMethodList()}</div>
            {renderPhoneMethodInput(listMethod)}
          </div>
          <Divider />
          <div>
            <Form form={form} layout="vertical" onFinish={sendSms}>
              <Form.Item
                name="message"
                label="ข้อความ:"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุข้อความ",
                  },
                  {
                    max: 670,
                    message: "สูงสุด 670 ตัวอักษร",
                  },
                ]}
              >
                <Input.TextArea placeholder="ข้อความ sms" autoSize={{ minRows: 10, maxRows: 20 }} allowClear />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={sending}
                  disabled={listMethod === "excel" ? !phoneListExcel.length : !phoneListCustom.filter((p) => p).length}
                >
                  {sending ? "กำลังส่งข้อความ..." : "ส่งข้อความ"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      }
      onOpenChange={(open) => {
        if (!open) {
          setTimeout(() => {
            setPhoneListExcel([]);
            setPhoneListCustom([""]);
            form.resetFields();
          }, 500);
        }
      }}
      overlayStyle={{
        width: 280,
      }}
    >
      <ButtonIcon type="primary" icon={<FaSms />} ghost>
        ส่งข้อความ
      </ButtonIcon>
    </Popover>
  );
}

export default memo(UploadSmsExcel);
